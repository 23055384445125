import { useTypedSelector } from '../../../../hooks/useTypedSelector';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { useActions } from '../../../../hooks/useActions';
import { useEffect, useState } from 'react';
import { getI18n, useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import fireToast from '../../../benit/central-ui/methods/fireToast';
// @ts-ignore
import FsLightbox from 'fslightbox-react';
import { LoadingAnimation } from '../../central-ui/components/Container/MainWindow/LoadingAnimation/LoadingAnimation';
import './DetailPage.css';

interface IProps {
    itemId: string;
}

const DetailPage = ({ itemId }: IProps) => {
    const host = window.location.host;
    const location = useLocation();

    const [passcodeManual, setPasscodeManual] = useState(false);

    const [toggler, setToggler] = useState(false);
    const [requestNextStep, setRequestNextStep] = useState(false);
    const [slide, setSlide] = useState(1);
    const [bilderTmp, setBilderTmp] = useState<string[]>([]);
    const [bilderCaptions, setBilderCaptions] = useState<string[]>([]);
    const [togglerGrundriss, setTogglerGrundriss] = useState(false);
    const [grundrissSlide, setGrundrissSlide] = useState(1);
    const [grundrissTmp, setGrundrissTmp] = useState<string[]>([]);
    const [grundrissCaptions, setGrundrissCaptions] = useState<string[]>([]);
    const [request, setRequest] = useState<{ [x: string]: string }>({ anrede: 'Anrede', firstName: '', lastName: '', eMail: '', telephone: '', customText: '', city: '', street: '', postcode: '' });
    const [stakeholderData, setStakeholderData] = useState<{ [x: string]: string | number }>({
        exposeCount: 0,
        terminCount: 0,
        auswahlCount: 0,
        ausgeschiedenCount: 0,
        gesamtCount: 0,
        exposePercentage: 0,
        terminPercentage: 0,
        auswahlPercentage: 0,
        ausgeschiedenPercentage: 0,
        gesamtPercentage: 0,
    });
    const [selectedStakeholderState, setSelectedStakeholderState] = useState<string | null>(null);
    const history = useHistory();

    const { benit_homepage_get_detail_entry, ri_homepage_send_request } = useActions();

    const { detailEntry, detailEntryExists, passcodeLength, passcodeCorrect, requestSuccessful } = useTypedSelector((state) => state.riReducer);

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const passcode = queryParams.get('passcode');
        const ownerPasscode = queryParams.get('ownerPasscode');

        benit_homepage_get_detail_entry(itemId, passcode, ownerPasscode);
    }, []);

    useEffect(() => {
        if (passcodeCorrect && passcodeManual) {
            fireToast('success', `Seite erfolgreich freigeschaltet`);
        }
    }, [passcodeCorrect]);

    useEffect(() => {
        if (requestSuccessful) {
            //@ts-ignore
            // const liveToast = new bootstrap.Toast(document.querySelector('#liveToast'));
            // liveToast.show();
            fireToast('success', `Anfrage erfolgreich versendet`);
        }
    }, [requestSuccessful]);

    useEffect(() => {
        if (stakeholderData.gesamtCount > 0) {
            // @ts-ignore
            HSCore.components.HSCircles.init('.js-circle');
        }
    }, [stakeholderData]);

    useEffect(() => {
        if (detailEntryExists) {
            setBilderTmp([]);
            Object.entries(detailEntry.pictures).map((picture: any, index: number) => {
                setBilderTmp((oldArray) => [
                    ...oldArray,
                    `https://${
                        host === 'mockups.benit.cloud' || host === 'rauprecht-immobilien.de' || host === 'www.rauprecht-immobilien.de' ? 'benit.cloud' : 'benit.dev'
                    }/api/ri/real-estate/objekt/files/${picture[0]}`,
                ]);
                setBilderCaptions((oldArray) => [...oldArray, picture[1].titlePicture ? picture[1].titlePicture : '']);
            });
            setGrundrissTmp([]);
            Object.entries(detailEntry.pictures).map((picture: any, index: number) => {
                if (picture[1].floorplan) {
                    setGrundrissTmp((oldArray) => [
                        ...oldArray,
                        `https://${
                            host === 'mockups.benit.cloud' || host === 'rauprecht-immobilien.de' || host === 'www.rauprecht-immobilien.de' ? 'benit.cloud' : 'benit.dev'
                        }/api/ri/real-estate/objekt/files/${picture[0]}`,
                    ]);
                    setGrundrissCaptions((oldArray) => [...oldArray, picture[1].titlePicture ? picture[1].titlePicture : '']);
                }
            });
            if (detailEntry.stakeholder) {
                let exposeCount = 0;
                let terminCount = 0;
                let auswahlCount = 0;
                let ausgeschiedenCount = 0;
                let gesamtCount = 0;

                for (const stakeholder of detailEntry.stakeholder) {
                    if (stakeholder.status && stakeholder.status === 'EXPOSE') {
                        exposeCount++;
                        gesamtCount++;
                    }
                    if (stakeholder.status && stakeholder.status === 'TERMIN') {
                        terminCount++;
                        gesamtCount++;
                    }
                    if (stakeholder.status && stakeholder.status === 'AUSWAHL') {
                        auswahlCount++;
                        gesamtCount++;
                    }
                    if (stakeholder.status && stakeholder.status === 'AUSGESCHIEDEN') {
                        ausgeschiedenCount++;
                    }
                }

                let gesamtPercentage = 100;
                let allStakeholderCount = detailEntry.stakeholder.length;

                let exposePercentage = Math.ceil((exposeCount / allStakeholderCount) * 100);
                let terminPercentage = Math.ceil((terminCount / allStakeholderCount) * 100);
                let auswahlPercentage = Math.ceil((auswahlCount / allStakeholderCount) * 100);
                let ausgeschiedenPercentage = gesamtPercentage - exposePercentage - terminPercentage - auswahlPercentage;

                setStakeholderData({
                    exposeCount,
                    terminCount,
                    auswahlCount,
                    ausgeschiedenCount,
                    gesamtCount,
                    exposePercentage,
                    terminPercentage,
                    auswahlPercentage,
                    ausgeschiedenPercentage,
                    gesamtPercentage,
                });
            }
        }
    }, [detailEntryExists, passcodeCorrect]);

    const currency = (number: number) => {
        return new Intl.NumberFormat('de-de', {
            style: 'currency',
            currency: 'EUR',
            minimumFractionDigits: 2,
        }).format(number);
    };

    const toNumber = (number: number) => {
        return new Intl.NumberFormat('de-de').format(number);
    };

    useEffect(() => {
        // INITIALIZATION OF STICKY BLOCKS
        // =======================================================
        // @ts-ignore
        new HSStickyBlock('.js-sticky-block', {
            // @ts-ignore
            targetSelector: '#header',
            //targetSelector: document.getElementById('header') ? (document.getElementById('header').classList.contains('navbar-fixed') ? '#header' : null) : null,
        });

        // INITIALIZATION OF NAV SCROLLER
        // =======================================================
        // @ts-ignore
        new HsNavScroller('.js-nav-scroller');

        // // INITIALIZATION OF LEAFLET
        // // =======================================================
        // // @ts-ignore
        // const leaflet = HSCore.components.HSLeaflet.init(document.getElementById('map'));
        // // @ts-ignore
        // L.tileLayer('https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoibWFwYm94IiwiYSI6ImNpejY4NXVycTA2emYycXBndHRqcmZ3N3gifQ.rJcFIG214AriISLbB6B5aw', {
        //     id: 'mapbox/light-v9',
        // }).addTo(leaflet);
    }, []);

    const toDate = (date: Date) => {
        return new Intl.DateTimeFormat('de-de', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
        }).format(new Date(date).getTime());
    };

    const stakeholderCard = (stakeholder: any) => {
        return (
            <div className="col-md-6 mb-3">
                <div className="card h-100">
                    <div className="card-body">
                        <span className="card-subtitle">
                            {!stakeholder.status || stakeholder.status === 'EXPOSE' ? <span className="badge bg-secondary">Exposé erhalten</span> : null}
                            {stakeholder.status && stakeholder.status === 'TERMIN' ? <span className="badge bg-info">Besichtigungstermin vereinbart</span> : null}
                            {stakeholder.status && stakeholder.status === 'AUSWAHL' ? <span className="badge bg-success">Weiteres Interesse</span> : null}
                            {stakeholder.status && stakeholder.status === 'AUSGESCHIEDEN' ? <span className="badge bg-danger">Abgesagt / Ausgeschieden</span> : null}
                        </span>
                        <h4 className="card-title">
                            {stakeholder.data.firstName} {stakeholder.data.lastName}
                        </h4>

                        <p className="card-text">
                            {stakeholder.status && stakeholder.status === 'AUSGESCHIEDEN'
                                ? stakeholder.data.declineReason && stakeholder.data.declineReason.length > 1
                                    ? stakeholder.data.declineReason.replace(/<\/?[^>]+(>|$)/g, '')
                                    : stakeholder.data.requestText.replace(/<\/?[^>]+(>|$)/g, '')
                                : stakeholder.data.requestText.replace(/<\/?[^>]+(>|$)/g, '')}
                        </p>
                        {/* {stakeholder.data.requestTextTranslated ? <p className="card-text">{stakeholder.data.requestTextTranslated}</p> : null} */}
                        {stakeholder.data.agentComment && stakeholder.data.agentComment.length > 0 ? (
                            <ul className="list-group list-group-horizontal mb-3">
                                <li className="list-group-item d-sm-flex align-items-sm-center w-100">
                                    <i className="bi-pen list-group-icon" /> {stakeholder.data.agentComment}
                                </li>
                            </ul>
                        ) : null}
                        {stakeholder.data.requestReceivedAt && stakeholder.data.requestReceivedAt.length > 0 ? (
                            <ul className="list-group list-group-horizontal mb-3">
                                <li className="list-group-item d-sm-flex align-items-sm-center w-100">
                                    <i className="bi-envelope-open list-group-icon" /> {stakeholder.data.requestReceivedAt}
                                </li>
                            </ul>
                        ) : null}
                        {stakeholder.data.interestEmailSent ? (
                            <ul className="list-group list-group-horizontal mb-3">
                                <li className="list-group-item d-sm-flex align-items-sm-center w-100">
                                    <i className="bi-question-square list-group-icon" /> Frage nach weiterem Interesse verschickt
                                </li>
                            </ul>
                        ) : null}
                        {stakeholder.data.city && stakeholder.data.city.length > 0 ? (
                            <ul className="list-group list-group-horizontal mb-3">
                                <li className="list-group-item d-sm-flex align-items-sm-center w-100">
                                    <i className="bi-house list-group-icon" /> {stakeholder.data.city}
                                </li>
                            </ul>
                        ) : null}
                        {stakeholder.data.appointment && stakeholder.data.appointment.length > 0 ? (
                            <ul className="list-group list-group-horizontal">
                                <li className="list-group-item d-sm-flex align-items-sm-center w-100">
                                    <i className="bi-clock list-group-icon" /> {stakeholder.data.appointment}
                                </li>
                            </ul>
                        ) : null}
                    </div>
                </div>
            </div>
        );
    };

    return (
        <>
            {!detailEntryExists ? <LoadingAnimation /> : null}
            <div className={`${!detailEntryExists ? 'd-none' : null} `}>
                {/* Gallery */}
                <div className="container mb-3">
                    <div className="row align-items-lg-center mb-3">
                        <div className="col-lg mb-2 mb-lg-0">
                            {/* Breadcrumb */}
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mb-0">
                                    <li className="breadcrumb-item">
                                        <Link to="/angebote" onClick={(e) => history.push('/angebote')}>
                                            Angebote
                                        </Link>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">
                                        {detailEntryExists && detailEntry.title ? detailEntry.title : null}
                                    </li>
                                </ol>
                            </nav>
                            {/* End Breadcrumb */}
                        </div>
                        {/* End Col */}
                        {/* <div className="col-lg-auto">
                            <a className="btn btn-sm btn-ghost-secondary" href="javascript:;">
                                <i className="bi-share-fill me-2" /> Teilen
                            </a>
                        </div> */}
                        {/* End Col */}
                    </div>
                    {/* End Row */}
                    {detailEntryExists && detailEntry.stakeholder ? (
                        <>
                            <div className="row mb-4">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-header">
                                            <h3 className="card-title text-center">Interessenten</h3>
                                        </div>
                                        <div className="card-body p-2 p-lg-5 pt-5">
                                            <div className="row mb-5">
                                                <div className="col-12">
                                                    <div className="progress" style={{ height: '20px' }}>
                                                        <div
                                                            className="progress-bar bg-secondary"
                                                            role="progressbar"
                                                            style={{
                                                                cursor: 'pointer',
                                                                width: `${stakeholderData.exposePercentage ? stakeholderData.exposePercentage : '0'}%`,
                                                                opacity: `${!selectedStakeholderState || selectedStakeholderState === 'EXPOSE' ? '1' : '0.3'}`,
                                                            }}
                                                            onClick={(e) => (selectedStakeholderState === 'EXPOSE' ? setSelectedStakeholderState(null) : setSelectedStakeholderState('EXPOSE'))}
                                                        />
                                                        <div
                                                            className="progress-bar bg-info"
                                                            role="progressbar"
                                                            style={{
                                                                cursor: 'pointer',
                                                                width: `${stakeholderData.terminPercentage ? stakeholderData.terminPercentage : '0'}%`,
                                                                opacity: `${!selectedStakeholderState || selectedStakeholderState === 'TERMIN' ? '1' : '0.3'}`,
                                                            }}
                                                            onClick={(e) => (selectedStakeholderState === 'TERMIN' ? setSelectedStakeholderState(null) : setSelectedStakeholderState('TERMIN'))}
                                                        />
                                                        <div
                                                            className="progress-bar bg-success"
                                                            role="progressbar"
                                                            style={{
                                                                cursor: 'pointer',
                                                                width: `${stakeholderData.auswahlPercentage ? stakeholderData.auswahlPercentage : '0'}%`,
                                                                opacity: `${!selectedStakeholderState || selectedStakeholderState === 'AUSWAHL' ? '1' : '0.3'}`,
                                                            }}
                                                            onClick={(e) => (selectedStakeholderState === 'AUSWAHL' ? setSelectedStakeholderState(null) : setSelectedStakeholderState('AUSWAHL'))}
                                                        />
                                                        <div
                                                            className="progress-bar bg-danger"
                                                            role="progressbar"
                                                            style={{
                                                                cursor: 'pointer',
                                                                width: `${stakeholderData.ausgeschiedenPercentage ? stakeholderData.ausgeschiedenPercentage : '0'}%`,
                                                                opacity: `${!selectedStakeholderState || selectedStakeholderState === 'AUSGESCHIEDEN' ? '1' : '0.3'}`,
                                                            }}
                                                            onClick={(e) =>
                                                                selectedStakeholderState === 'AUSGESCHIEDEN' ? setSelectedStakeholderState(null) : setSelectedStakeholderState('AUSGESCHIEDEN')
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div className="row mb-2">
                                                <div className="col-12">
                                                    <div className="row">
                                                        <div className="col-sm-6 col-lg-3 mb-7 mb-lg-4">
                                                            <div className={`circles-chart`}>
                                                                <div
                                                                    className="js-circle user-select-none"
                                                                    onClick={(e) => setSelectedStakeholderState(null)}
                                                                    style={{ cursor: 'pointer' }}
                                                                    data-hs-circles-options={`{
                                                                        "value": ${stakeholderData.gesamtCount ? stakeholderData.gesamtCount : 0},
                                                                        "maxValue": ${stakeholderData.gesamtCount ? stakeholderData.gesamtCount : 0},
                                                                        "duration": 800,
                                                                        "isViewportInit": true,
                                                                        "colors": ["#f8fafd", "#DAD1AE"],
                                                                        "radius": 100,
                                                                        "width": 8,
                                                                        "fgStrokeLinecap": "round",
                                                                        "fgStrokeMiterlimit": "10",
                                                                        "isHideValue": true,
                                                                        "additionalText": ${stakeholderData.gesamtCount ? stakeholderData.gesamtCount : 0},
                                                                        "additionalTextType": "prefix",
                                                                        "textClass": "circles-chart-content",
                                                                        "textFontSize": 24,
                                                                        "textFontWeight": 500,
                                                                        "textColor": "#DAD1AE",
                                                                        "secondaryText": ${
                                                                            stakeholderData.gesamtCount === 0
                                                                                ? `"Bisher gibt es keine aktiven Interessenten"`
                                                                                : stakeholderData.gesamtCount > 1
                                                                                ? `"aktive Interessenten gibt es insgesamt"`
                                                                                : `"aktiven Interessenten gibt es insgesamt"`
                                                                        },
                                                                        "secondaryTextColor": "#77838f",
                                                                        "secondaryTextFontSize": "13",
                                                                        "secondaryTextFontWeight": "400",
                                                                        "dividerSpace": "10"
                                                                      }`}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6 col-lg-3 mb-7 mb-lg-4">
                                                            <div className={`circles-chart ${selectedStakeholderState === 'EXPOSE' ? 'bg-dark' : null}`}>
                                                                <div
                                                                    className="js-circle user-select-none"
                                                                    onClick={(e) => (selectedStakeholderState === 'EXPOSE' ? setSelectedStakeholderState(null) : setSelectedStakeholderState('EXPOSE'))}
                                                                    style={{ cursor: 'pointer' }}
                                                                    data-hs-circles-options={`{
                                                                        "value": ${stakeholderData.exposeCount ? stakeholderData.exposeCount : 0},
                                                                        "maxValue": ${stakeholderData.gesamtCount ? stakeholderData.gesamtCount : 0},
                                                                        "duration": 800,
                                                                        "isViewportInit": true,
                                                                        "colors": ["#DAD1AE", "#71859D"],
                                                                        "radius": 100,
                                                                        "width": 8,
                                                                        "fgStrokeLinecap": "round",
                                                                        "fgStrokeMiterlimit": "10",
                                                                        "isHideValue": true,
                                                                        "additionalText": ${stakeholderData.exposeCount ? stakeholderData.exposeCount : 0},
                                                                        "additionalTextType": "prefix",
                                                                        "textClass": "circles-chart-content",
                                                                        "textFontSize": 24,
                                                                        "textFontWeight": 500,
                                                                        "textColor": "#71859D",
                                                                        "secondaryText": ${
                                                                            stakeholderData.exposeCount === 0
                                                                                ? `"Bisher gab es noch keine Exposé-Anfragen"`
                                                                                : stakeholderData.exposeCount > 1
                                                                                ? `"Interessenten haben das Objekt-Exposé erhalten, aber noch keinen Termin vereinbart"`
                                                                                : `"Interessent hat das Objekt-Exposé erhalten, aber noch keinen Termin vereinbart"`
                                                                        },
                                                                        "secondaryTextColor": "#77838f",
                                                                        "secondaryTextFontSize": "13",
                                                                        "secondaryTextFontWeight": "400",
                                                                        "dividerSpace": "10"
                                                                      }`}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6 col-lg-3 mb-7 mb-lg-4">
                                                            <div className={`circles-chart ${selectedStakeholderState === 'TERMIN' ? 'bg-dark' : null}`}>
                                                                <div
                                                                    className="js-circle user-select-none"
                                                                    onClick={(e) => (selectedStakeholderState === 'TERMIN' ? setSelectedStakeholderState(null) : setSelectedStakeholderState('TERMIN'))}
                                                                    style={{ cursor: 'pointer' }}
                                                                    data-hs-circles-options={`{
                                                                        "value": ${stakeholderData.terminCount ? stakeholderData.terminCount : 0},
                                                                        "maxValue": ${stakeholderData.gesamtCount ? stakeholderData.gesamtCount : 0},
                                                                        "duration": 800,
                                                                        "isViewportInit": true,
                                                                        "colors": ["#DAD1AE", "#06A4BE"],
                                                                        "radius": 100,
                                                                        "width": 8,
                                                                        "fgStrokeLinecap": "round",
                                                                        "fgStrokeMiterlimit": "10",
                                                                        "isHideValue": true,
                                                                        "additionalText": ${stakeholderData.terminCount ? stakeholderData.terminCount : 0},
                                                                        "additionalTextType": "prefix",
                                                                        "textClass": "circles-chart-content",
                                                                        "textFontSize": 24,
                                                                        "textFontWeight": 500,
                                                                        "textColor": "#06A4BE",
                                                                        "secondaryText": ${
                                                                            stakeholderData.terminCount === 0
                                                                                ? `"Bisher wurden noch keine Besichtigungstermine mit Interessenten vereinbart"`
                                                                                : stakeholderData.terminCount > 1
                                                                                ? `"Interessenten haben nach Erhalt des Exposés einen Besichtigungstermin vereinbart"`
                                                                                : `"Interessent hat nach Erhalt des Exposés einen Besichtigungstermin vereinbart"`
                                                                        },
                                                                        "secondaryTextColor": "#77838f",
                                                                        "secondaryTextFontSize": "13",
                                                                        "secondaryTextFontWeight": "400",
                                                                        "dividerSpace": "10"
                                                                      }`}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6 col-lg-3 mb-7 mb-lg-4">
                                                            <div className={`circles-chart ${selectedStakeholderState === 'AUSWAHL' ? 'bg-dark' : null}`}>
                                                                <div
                                                                    className="js-circle user-select-none"
                                                                    onClick={(e) =>
                                                                        selectedStakeholderState === 'AUSWAHL' ? setSelectedStakeholderState(null) : setSelectedStakeholderState('AUSWAHL')
                                                                    }
                                                                    style={{ cursor: 'pointer' }}
                                                                    data-hs-circles-options={`{
                                                                        "value": ${stakeholderData.auswahlCount ? stakeholderData.auswahlCount : 0},
                                                                        "maxValue": ${stakeholderData.gesamtCount ? stakeholderData.gesamtCount : 0},
                                                                        "duration": 800,
                                                                        "isViewportInit": true,
                                                                        "colors": ["#DAD1AE", "#05C9A6"],
                                                                        "radius": 100,
                                                                        "width": 8,
                                                                        "fgStrokeLinecap": "round",
                                                                        "fgStrokeMiterlimit": "10",
                                                                        "isHideValue": true,
                                                                        "additionalText": ${stakeholderData.auswahlCount ? stakeholderData.auswahlCount : 0},
                                                                        "additionalTextType": "prefix",
                                                                        "textClass": "circles-chart-content",
                                                                        "textFontSize": 24,
                                                                        "textFontWeight": 500,
                                                                        "textColor": "#05C9A6",
                                                                        "secondaryText": ${
                                                                            stakeholderData.auswahlCount === 0
                                                                                ? `"Bisher gibt es noch keine Interessenten die nach dem Besichtigungstermin weiteres Interesse geäußert haben"`
                                                                                : stakeholderData.auswahlCount > 1
                                                                                ? `"Interessenten haben nach dem Besichtigungstermin noch weiteres Interesse am Objekt"`
                                                                                : `"Interessent hat nach dem Besichtigungstermin noch weiteres Interesse am Objekt"`
                                                                        },
                                                                        "secondaryTextColor": "#77838f",
                                                                        "secondaryTextFontSize": "13",
                                                                        "secondaryTextFontWeight": "400",
                                                                        "dividerSpace": "10"
                                                                      }`}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> */}
                                            {!selectedStakeholderState || selectedStakeholderState === 'EXPOSE' ? (
                                                <div className="row">
                                                    {detailEntry.stakeholder.map((stakeholder: any) => {
                                                        if (!stakeholder.status || stakeholder.status === 'EXPOSE') {
                                                            return stakeholderCard(stakeholder);
                                                        } else {
                                                            return null;
                                                        }
                                                    })}
                                                </div>
                                            ) : null}
                                            {!selectedStakeholderState || selectedStakeholderState === 'TERMIN' ? (
                                                <div className="row">
                                                    {detailEntry.stakeholder.map((stakeholder: any) => {
                                                        if (stakeholder.status && stakeholder.status === 'TERMIN') {
                                                            return stakeholderCard(stakeholder);
                                                        } else {
                                                            return null;
                                                        }
                                                    })}
                                                </div>
                                            ) : null}
                                            {!selectedStakeholderState || selectedStakeholderState === 'AUSWAHL' ? (
                                                <div className="row">
                                                    {detailEntry.stakeholder.map((stakeholder: any) => {
                                                        if (stakeholder.status && stakeholder.status === 'AUSWAHL') {
                                                            return stakeholderCard(stakeholder);
                                                        } else {
                                                            return null;
                                                        }
                                                    })}
                                                </div>
                                            ) : null}
                                            {!selectedStakeholderState || selectedStakeholderState === 'AUSGESCHIEDEN' ? (
                                                <div className="row">
                                                    {detailEntry.stakeholder.map((stakeholder: any) => {
                                                        if (stakeholder.status && stakeholder.status === 'AUSGESCHIEDEN') {
                                                            return stakeholderCard(stakeholder);
                                                        } else {
                                                            return null;
                                                        }
                                                    })}
                                                </div>
                                            ) : null}
                                        </div>
                                    </div>
                                    {/* End Card */}
                                </div>
                            </div>
                        </>
                    ) : null}

                    <div className="rounded-2 overflow-hidden">
                        <div className="row gx-2">
                            <div className="col-md-8">
                                {/* Gallery */}
                                <div className="d-block position-relative">
                                    {detailEntryExists && detailEntry.pictures
                                        ? Object.entries(detailEntry.pictures).map((picture: any, index: number) =>
                                              index === 0 ? (
                                                  <>
                                                      <img
                                                          className="img-fluid w-100"
                                                          src={`https://${
                                                              host === 'mockups.benit.cloud' || host === 'rauprecht-immobilien.de' || host === 'www.rauprecht-immobilien.de'
                                                                  ? 'benit.cloud'
                                                                  : 'benit.dev'
                                                          }/api/ri/real-estate/objekt/files/${picture[0]}`}
                                                          alt="Image Description"
                                                          onClick={() => {
                                                              setToggler(!toggler);
                                                              setSlide(1);
                                                          }}
                                                          style={{ cursor: 'pointer' }}
                                                      />
                                                      <div className="position-absolute bottom-0 end-0 mb-3 me-3" onClick={() => setToggler(!toggler)}>
                                                          <span className="d-md-none btn btn-sm btn-light">
                                                              <i className="bi-arrows-fullscreen me-2" /> Fotos anschauen
                                                          </span>
                                                      </div>
                                                  </>
                                              ) : null
                                          )
                                        : null}
                                </div>
                                {/* End Gallery */}
                            </div>
                            {/* End Col */}
                            <div className="col-md-4 d-none d-md-inline-block">
                                {/* Gallery */}
                                <div className="d-block mb-2">
                                    {detailEntryExists && detailEntry.pictures
                                        ? Object.entries(detailEntry.pictures).map((picture: any, index: number) =>
                                              index === 1 ? (
                                                  <>
                                                      <img
                                                          className="img-fluid w-100"
                                                          src={`https://${
                                                              host === 'mockups.benit.cloud' || host === 'rauprecht-immobilien.de' || host === 'www.rauprecht-immobilien.de'
                                                                  ? 'benit.cloud'
                                                                  : 'benit.dev'
                                                          }/api/ri/real-estate/objekt/files/${picture[0]}`}
                                                          alt="Image Description"
                                                          onClick={() => {
                                                              setToggler(!toggler);
                                                              setSlide(2);
                                                          }}
                                                          style={{ cursor: 'pointer' }}
                                                      />
                                                  </>
                                              ) : null
                                          )
                                        : null}
                                </div>
                                {/* End Gallery */}
                                {/* Gallery */}
                                <div className="d-block position-relative">
                                    {detailEntryExists && detailEntry.pictures
                                        ? Object.entries(detailEntry.pictures).map((picture: any, index: number) =>
                                              index === 2 ? (
                                                  <>
                                                      <img
                                                          className="img-fluid w-100"
                                                          src={`https://${
                                                              host === 'mockups.benit.cloud' || host === 'rauprecht-immobilien.de' || host === 'www.rauprecht-immobilien.de'
                                                                  ? 'benit.cloud'
                                                                  : 'benit.dev'
                                                          }/api/ri/real-estate/objekt/files/${picture[0]}`}
                                                          alt="Image Description"
                                                          onClick={() => {
                                                              setToggler(!toggler);
                                                              setSlide(3);
                                                          }}
                                                          style={{ cursor: 'pointer' }}
                                                      />
                                                      <div className="position-absolute bottom-0 end-0 mb-3 me-3" onClick={() => setToggler(!toggler)}>
                                                          <span className="d-none d-md-inline-block btn btn-sm btn-light">
                                                              <i className="bi-arrows-fullscreen me-2" /> Fotos anschauen
                                                          </span>
                                                      </div>
                                                  </>
                                              ) : null
                                          )
                                        : null}
                                </div>
                                {/* End Gallery */}

                                {detailEntryExists && detailEntry.pictures
                                    ? Object.entries(detailEntry.pictures).map((picture: any, index: number) => (
                                          <>
                                              <a
                                                  className="d-none"
                                                  href={`https://${
                                                      host === 'mockups.benit.cloud' || host === 'rauprecht-immobilien.de' || host === 'www.rauprecht-immobilien.de' ? 'benit.cloud' : 'benit.dev'
                                                  }/api/ri/real-estate/objekt/files/${picture[0]}`}
                                                  data-fslightbox="propertyOverviewGallery"
                                              />
                                          </>
                                      ))
                                    : null}
                            </div>
                            {/* End Col */}
                        </div>
                        {/* End Row */}
                        <FsLightbox
                            toggler={toggler}
                            sources={bilderTmp}
                            slide={slide}
                            /*
                            // @ts-ignore */
                            captions={bilderCaptions}
                        />
                    </div>
                    {/* <div className="d-flex justify-content-end mt-2">
                            <span className="small text-dark fw-semi-bold">Veröffentlicht:</span>
                            <span className="small ms-1">{detailEntryExists ? toDate(detailEntry.published_at) : null}</span>
                        </div> */}
                    <div className="row mt-6">
                        <div className={`${!passcodeCorrect && !requestSuccessful ? 'col-lg-8' : 'col-lg-12'} mb-9 mb-lg-0 ${requestNextStep ? 'd-none' : ''}`}>
                            {detailEntryExists ? (
                                <>
                                    <div className="row justify-content-lg-between mb-4">
                                        <h1 className="h2 mb-0">{detailEntry.title ? detailEntry.title : null}</h1>
                                    </div>
                                    <div className="row justify-content-lg-between mb-3">
                                        <div className="col-12 col-sm-6 mb-5 mb-sm-0">
                                            <span className="d-block text-dark mb-3">
                                                {detailEntry.realStreet ? detailEntry.realStreet : detailEntry.showAddress && detailEntry.showAddress ? detailEntry.street : null}{' '}
                                                {detailEntry.realHouseNumber
                                                    ? ` ${detailEntry.realHouseNumber}, `
                                                    : detailEntry.showAddress && detailEntry.showAddress
                                                    ? ` ${detailEntry.houseNumber}, `
                                                    : null}
                                                {detailEntry.postcode ? detailEntry.postcode : null} {detailEntry.city ? detailEntry.city : null}
                                                {!detailEntry.showAddress && !(detailEntry.realStreet && detailEntry.realHouseNumber) ? ' (Bitte kontaktieren Sie uns für die genaue Adresse)' : null}
                                            </span>
                                            {/* <ul className="list-inline list-separator font-size-1 text-body">
                                                {detailEntry.numberOfBedRooms && detailEntry.numberOfBedRooms > 0 ? (
                                                    <li className="list-inline-item">
                                                        <i className="fas fa-bed text-muted me-1" /> {detailEntry.numberOfBedRooms} Schlafzimmer
                                                    </li>
                                                ) : null}
                                                {detailEntry.numberOfBedRooms && detailEntry.numberOfBedRooms > 0 ? (
                                                    <li className="list-inline-item">
                                                        <i className="fas fa-bath text-muted me-1" /> {detailEntry.numberOfBedRooms} {detailEntry.numberOfBedRooms > 1 ? 'Bäder' : 'Bad'}
                                                    </li>
                                                ) : null}
                                                {detailEntry.livingSpace && detailEntry.livingSpace > 0 ? (
                                                    <li className="list-inline-item">
                                                        <i className="fas fa-ruler-combined text-muted me-1" />{' '}
                                                        {Intl.NumberFormat('de-de', {
                                                            minimumFractionDigits: 0,
                                                        }).format(detailEntry.livingSpace)}{' '}
                                                        ㎡
                                                    </li>
                                                ) : null}
                                            </ul> */}
                                        </div>
                                        <div className="col-sm-6 column-divider-sm text-center">
                                            <div className="pl-md-4">
                                                <h2 className="mb-0">
                                                    {detailEntry.realEstateType === 'realestates.houseBuy' ? currency(detailEntry.value) : null}
                                                    {detailEntry.realEstateType === 'realestates.houseRent' ? `${currency(detailEntry.baseRent)} / Monat (kalt)` : null}
                                                    {detailEntry.realEstateType === 'realestates.apartmentBuy' ? currency(detailEntry.value) : null}
                                                    {detailEntry.realEstateType === 'realestates.apartmentRent' ? `${currency(detailEntry.baseRent)} / Monat (kalt)` : null}
                                                    {detailEntry.realEstateType === 'realestates.livingBuySite' ? currency(detailEntry.value) : null}
                                                    {detailEntry.realEstateType === 'realestates.office' ? `${currency(detailEntry.value)} / Monat (kalt)` : null}
                                                    {detailEntry.realEstateType === 'realestates.investment' ? currency(detailEntry.value) : null}
                                                    {detailEntry.realEstateType === 'realestates.garageBuy' ? currency(detailEntry.value) : null}
                                                    {detailEntry.realEstateType === 'realestates.garageRent' ? `${currency(detailEntry.value)} / Monat` : null}
                                                    {detailEntry.realEstateType === 'realestates.store' ? `${currency(detailEntry.value)} / Monat (kalt)` : null}
                                                    {detailEntry.realEstateType === 'realestates.tradeSite' ? currency(detailEntry.value) : null}
                                                </h2>
                                                {/* <span className="d-block text-dark mb-3">Bsp. Kreditrate 1.400 €/mo</span>
                                        <a href="#">Sprechen Sie mit uns über einen Kredit</a> */}
                                            </div>
                                        </div>
                                    </div>
                                </>
                            ) : null}
                            {/* End Row */}
                            {/* Nav Scroller */}
                            {grundrissTmp.length > 0 ? (
                                <div className="js-nav-scroller hs-nav-scroller-horizontal">
                                    <span className="hs-nav-scroller-arrow-prev" style={{ display: 'none' }}>
                                        <a className="hs-nav-scroller-arrow-link" href="javascript:;">
                                            <i className="bi-chevron-left" />
                                        </a>
                                    </span>
                                    <span className="hs-nav-scroller-arrow-next" style={{ display: 'none' }}>
                                        <a className="hs-nav-scroller-arrow-link" href="javascript:;">
                                            <i className="bi-chevron-right" />
                                        </a>
                                    </span>
                                    {/* Nav */}
                                    <ul className="nav nav-segment nav-fill mb-7" id="propertyOverviewNavTab" role="tablist">
                                        <li className="nav-item" role="presentation">
                                            <a
                                                className="nav-link active"
                                                href="#propertyOverviewNavOne"
                                                id="propertyOverviewNavOne-tab"
                                                data-bs-toggle="tab"
                                                data-bs-target="#propertyOverviewNavOne"
                                                role="tab"
                                                aria-controls="propertyOverviewNavOne"
                                                aria-selected="true"
                                                style={{ minWidth: '7rem' }}
                                            >
                                                Details
                                            </a>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <a
                                                className="nav-link"
                                                href="#propertyOverviewNavTwo"
                                                id="propertyOverviewNavTwo-tab"
                                                data-bs-toggle="tab"
                                                data-bs-target="#propertyOverviewNavTwo"
                                                role="tab"
                                                aria-controls="propertyOverviewNavTwo"
                                                aria-selected="false"
                                                style={{ minWidth: '7rem' }}
                                            >
                                                Grundriss
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            ) : (
                                <hr className="my-6" />
                            )}
                            {/* End Nav Scroller */}
                            {/* Tab Content */}
                            <div className="tab-content">
                                <div className="tab-pane fade show active" id="propertyOverviewNavOne" role="tabpanel" aria-labelledby="propertyOverviewNavOne-tab">
                                    <div className="mb-4">
                                        <h4>Details zur Immobilie</h4>
                                    </div>

                                    {detailEntryExists ? (
                                        <>
                                            <div className="row justify-content-md-between mb-4 d-block">
                                                <div className="col-md-12">
                                                    {detailEntry.guestToilet_boolean ? <span className="feature-card">Gäste-WC</span> : null}
                                                    {detailEntry.cellar_boolean ? <span className="feature-card">Keller</span> : null}
                                                    {detailEntry.handicappedAccessible_boolean ? <span className="feature-card">Stufenloser Zugang</span> : null}
                                                    {detailEntry.summerResidencePractical_boolean ? <span className="feature-card">Als Ferienhaus geeignet</span> : null}
                                                    {detailEntry.listed_boolean ? <span className="feature-card">Denkmalschutzobjekt</span> : null}
                                                    {/* {detailEntry.lodgerFlat_boolean ? <span className="feature-card">Einliegerwohnung</span> : null} */}
                                                    {detailEntry.rented_boolean ? <span className="feature-card">Vermietet</span> : null}
                                                    {detailEntry.useAsFlatshareRoom_boolean ? <span className="feature-card">WG-geeignet</span> : null}

                                                    {detailEntry.petsAllowed_text === 'Ja' ? (
                                                        <span className="feature-card">Haustiere erlaubt</span>
                                                    ) : detailEntry.petsAllowed_text === 'verhandelbar' ? (
                                                        <span className="feature-card">Haustiere verhandelbar</span>
                                                    ) : null}
                                                    {detailEntry.builtInKitchen_boolean ? <span className="feature-card">Einbauküche</span> : null}
                                                    {detailEntry.lift_boolean ? <span className="feature-card">Personenaufzug</span> : null}
                                                    {detailEntry.fireplace_boolean ? <span className="feature-card">Kamin</span> : null}
                                                    {detailEntry.airConditioned_boolean ? <span className="feature-card">klimatisiert</span> : null}
                                                    {detailEntry.nonLivingEstate_boolean ? <span className="feature-card">Nicht-Wohngebäude</span> : null}
                                                    {detailEntry.balcony_boolean ? <span className="feature-card">Balkon</span> : null}
                                                    {detailEntry.garden_boolean ? <span className="feature-card">Garten/ -mitbenutzung</span> : null}
                                                    {detailEntry.certificateOfEligibilityNeeded_boolean ? <span className="feature-card">Wohnberechtigungsschein erforderlich</span> : null}
                                                    {detailEntry.shortTermConstructible_boolean ? <span className="feature-card">kurzfristig bebaubar</span> : null}
                                                    {detailEntry.buildingPermission_boolean ? <span className="feature-card">Baugenehmigung</span> : null}
                                                    {detailEntry.demolition_boolean ? <span className="feature-card">Abriss</span> : null}
                                                    {detailEntry.hasCanteen_boolean ? <span className="feature-card">Kantine</span> : null}
                                                    {detailEntry.highVoltage_boolean ? <span className="feature-card">Starkstrom</span> : null}
                                                    {detailEntry.kitchenComplete_boolean ? <span className="feature-card">Küche</span> : null}
                                                    {detailEntry.additionalServicesIncluded_boolean ? <span className="feature-card">Preis inklusive Zusatzservices</span> : null}
                                                    {detailEntry.goodsLift_boolean ? <span className="feature-card">Lastenaufzug</span> : null}
                                                    {detailEntry.ramp_boolean ? <span className="feature-card">Rampe</span> : null}
                                                </div>
                                            </div>
                                            <div className="row justify-content-md-between">
                                                <div className="col-md-6">
                                                    <dl className="row">
                                                        {detailEntry.buildingType && detailEntry.buildingType.length > 0 ? (
                                                            <>
                                                                <dt className="col-6">Typ:</dt>
                                                                <dd className="col-6">{detailEntry.buildingType_text}</dd>
                                                            </>
                                                        ) : null}
                                                        {detailEntry.numberOfFloors && detailEntry.numberOfFloors > 0 ? (
                                                            <>
                                                                <dt className="col-6">Etagenanzahl:</dt>
                                                                <dd className="col-6">{detailEntry.numberOfFloors}</dd>
                                                            </>
                                                        ) : null}
                                                        {detailEntry.livingSpace && detailEntry.livingSpace > 0 ? (
                                                            <>
                                                                <dt className="col-6">Wohnfläche ca.:</dt>
                                                                <dd className="col-6">{toNumber(detailEntry.livingSpace)} ㎡</dd>
                                                            </>
                                                        ) : null}
                                                        {detailEntry.usableFloorSpace && detailEntry.usableFloorSpace > 0 ? (
                                                            <>
                                                                <dt className="col-6">Nutzfläche ca.:</dt>
                                                                <dd className="col-6">{toNumber(detailEntry.usableFloorSpace)} ㎡</dd>
                                                            </>
                                                        ) : null}
                                                        {detailEntry.freeFrom && detailEntry.freeFrom.length > 0 ? (
                                                            <>
                                                                <dt className="col-6">Frei ab:</dt>
                                                                <dd className="col-6">{detailEntry.freeFrom}</dd>
                                                            </>
                                                        ) : null}
                                                        {detailEntry.freeUntil && detailEntry.freeUntil.length > 0 ? (
                                                            <>
                                                                <dt className="col-6">Frei bis:</dt>
                                                                <dd className="col-6">{detailEntry.freeUntil}</dd>
                                                            </>
                                                        ) : null}
                                                        {detailEntry.apartmentType && detailEntry.apartmentType.length > 0 ? (
                                                            <>
                                                                <dt className="col-6">Wohnungskategorie:</dt>
                                                                <dd className="col-6">{detailEntry.apartmentType_text}</dd>
                                                            </>
                                                        ) : null}
                                                        {detailEntry.floor && detailEntry.floor > 0 ? (
                                                            <>
                                                                <dt className="col-6">Etage:</dt>
                                                                <dd className="col-6">{toNumber(detailEntry.floor)}</dd>
                                                            </>
                                                        ) : null}
                                                        {detailEntry.recommendedUseTypes && detailEntry.recommendedUseTypes.length > 0 ? (
                                                            <>
                                                                <dt className="col-6">Empfohlene Nutzungsart:</dt>
                                                                <dd className="col-6">{detailEntry.recommendedUseTypes_text}</dd>
                                                            </>
                                                        ) : null}
                                                        {detailEntry.minDivisible && detailEntry.minDivisible > 0 ? (
                                                            <>
                                                                <dt className="col-6">Teilbar ab:</dt>
                                                                <dd className="col-6">{toNumber(detailEntry.minDivisible)}</dd>
                                                            </>
                                                        ) : null}
                                                        {detailEntry.officeType && detailEntry.officeType.length > 0 ? (
                                                            <>
                                                                <dt className="col-6">Bürotyp:</dt>
                                                                <dd className="col-6">{detailEntry.officeType_text}</dd>
                                                            </>
                                                        ) : null}
                                                        {detailEntry.flooringType && detailEntry.flooringType.length > 0 ? (
                                                            <>
                                                                <dt className="col-6">Bodenbelag:</dt>
                                                                <dd className="col-6">{detailEntry.flooringType_text}</dd>
                                                            </>
                                                        ) : null}
                                                        {detailEntry.lanCables && detailEntry.lanCables.length > 0 ? (
                                                            <>
                                                                <dt className="col-6">Datenverkabelung:</dt>
                                                                <dd className="col-6">{detailEntry.lanCables_text}</dd>
                                                            </>
                                                        ) : null}
                                                        {detailEntry.airConditioning && detailEntry.airConditioning.length > 0 ? (
                                                            <>
                                                                <dt className="col-6">Klimaanlage:</dt>
                                                                <dd className="col-6">{detailEntry.airConditioning_text}</dd>
                                                            </>
                                                        ) : null}
                                                        {detailEntry.netFloorSpace && detailEntry.netFloorSpace > 0 ? (
                                                            <>
                                                                <dt className="col-6">vermietbare Fläche:</dt>
                                                                <dd className="col-6">{toNumber(detailEntry.netFloorSpace)} ㎡</dd>
                                                            </>
                                                        ) : null}
                                                        {detailEntry.totalFloorSpace && detailEntry.totalFloorSpace > 0 ? (
                                                            <>
                                                                <dt className="col-6">Gesamtfläche:</dt>
                                                                <dd className="col-6">{toNumber(detailEntry.totalFloorSpace)} ㎡</dd>
                                                            </>
                                                        ) : null}
                                                        {detailEntry.totalFloorSpace && detailEntry.totalFloorSpace > 0 ? (
                                                            <>
                                                                <dt className="col-6">Grundstücksfläche:</dt>
                                                                <dd className="col-6">{toNumber(detailEntry.totalFloorSpace)} ㎡</dd>
                                                            </>
                                                        ) : null}
                                                        {detailEntry.industrialArea && detailEntry.industrialArea > 0 ? (
                                                            <>
                                                                <dt className="col-6">Gewerbefläche:</dt>
                                                                <dd className="col-6">{toNumber(detailEntry.industrialArea)} ㎡</dd>
                                                            </>
                                                        ) : null}
                                                        {detailEntry.additionalArea && detailEntry.additionalArea > 0 ? (
                                                            <>
                                                                <dt className="col-6">Sonstige Fläche:</dt>
                                                                <dd className="col-6">{toNumber(detailEntry.additionalArea)} ㎡</dd>
                                                            </>
                                                        ) : null}
                                                        {detailEntry.investmentType && detailEntry.investmentType.length > 0 ? (
                                                            <>
                                                                <dt className="col-6">Anlagetyp:</dt>
                                                                <dd className="col-6">{detailEntry.investmentType_text}</dd>
                                                            </>
                                                        ) : null}
                                                        {detailEntry.pricePerSQM && detailEntry.pricePerSQM > 0 ? (
                                                            <>
                                                                <dt className="col-6">Preis pro ㎡:</dt>
                                                                <dd className="col-6">{currency(detailEntry.pricePerSQM)}</dd>
                                                            </>
                                                        ) : null}
                                                        {detailEntry.priceMultiplier && detailEntry.priceMultiplier > 0 ? (
                                                            <>
                                                                <dt className="col-6">x-fache Mieteinnahmen:</dt>
                                                                <dd className="col-6">{toNumber(detailEntry.priceMultiplier)}</dd>
                                                            </>
                                                        ) : null}
                                                        {detailEntry.rentalIncomeActual && detailEntry.rentalIncomeActual > 0 ? (
                                                            <>
                                                                <dt className="col-6">Mieteinnahmen Ist:</dt>
                                                                <dd className="col-6">{currency(detailEntry.rentalIncomeActual)}</dd>
                                                            </>
                                                        ) : null}
                                                        {detailEntry.rentalIncomeTarget && detailEntry.rentalIncomeTarget > 0 ? (
                                                            <>
                                                                <dt className="col-6">Mieteinnahmen Soll:</dt>
                                                                <dd className="col-6">{currency(detailEntry.rentalIncomeTarget)}</dd>
                                                            </>
                                                        ) : null}
                                                        {detailEntry.otherCosts && detailEntry.otherCosts > 0 ? (
                                                            <>
                                                                <dt className="col-6">nicht umlegbare Kosten:</dt>
                                                                <dd className="col-6">{currency(detailEntry.otherCosts)}</dd>
                                                            </>
                                                        ) : null}
                                                        {detailEntry.garageType && detailEntry.garageType.length > 0 ? (
                                                            <>
                                                                <dt className="col-6">Garagentyp:</dt>
                                                                <dd className="col-6">{detailEntry.garageType_text}</dd>
                                                            </>
                                                        ) : null}
                                                        {detailEntry.lengthGarage && detailEntry.lengthGarage > 0 ? (
                                                            <>
                                                                <dt className="col-6">Garagenlänge:</dt>
                                                                <dd className="col-6">{toNumber(detailEntry.lengthGarage)} m</dd>
                                                            </>
                                                        ) : null}
                                                        {detailEntry.widthGarage && detailEntry.widthGarage > 0 ? (
                                                            <>
                                                                <dt className="col-6">Garagenbreite:</dt>
                                                                <dd className="col-6">{toNumber(detailEntry.widthGarage)} m</dd>
                                                            </>
                                                        ) : null}
                                                        {detailEntry.heightGarage && detailEntry.heightGarage > 0 ? (
                                                            <>
                                                                <dt className="col-6">Garagenhöhe:</dt>
                                                                <dd className="col-6">{toNumber(detailEntry.heightGarage)} m</dd>
                                                            </>
                                                        ) : null}
                                                        {detailEntry.storeType && detailEntry.storeType.length > 0 ? (
                                                            <>
                                                                <dt className="col-6">Ladentyp:</dt>
                                                                <dd className="col-6">{detailEntry.storeType_text}</dd>
                                                            </>
                                                        ) : null}
                                                        {detailEntry.locationClassificationType && detailEntry.locationClassificationType.length > 0 ? (
                                                            <>
                                                                <dt className="col-6">Lageart:</dt>
                                                                <dd className="col-6">{detailEntry.locationClassificationType_text}</dd>
                                                            </>
                                                        ) : null}
                                                        {detailEntry.shopWindowLength && detailEntry.shopWindowLength > 0 ? (
                                                            <>
                                                                <dt className="col-6">Schaufensterfront-Länge:</dt>
                                                                <dd className="col-6">{toNumber(detailEntry.shopWindowLength)} m</dd>
                                                            </>
                                                        ) : null}
                                                        {detailEntry.goodsLiftLoad && detailEntry.goodsLiftLoad > 0 ? (
                                                            <>
                                                                <dt className="col-6">Lastenaufzug Tragkraft:</dt>
                                                                <dd className="col-6">{toNumber(detailEntry.goodsLiftLoad)} kg</dd>
                                                            </>
                                                        ) : null}
                                                        {detailEntry.floorLoad && detailEntry.floorLoad > 0 ? (
                                                            <>
                                                                <dt className="col-6">Deckenlast:</dt>
                                                                <dd className="col-6">{toNumber(detailEntry.floorLoad)} kg/m²</dd>
                                                            </>
                                                        ) : null}
                                                        {detailEntry.supplyType && detailEntry.supplyType.length > 0 ? (
                                                            <>
                                                                <dt className="col-6">Zulieferungsart:</dt>
                                                                <dd className="col-6">{detailEntry.supplyType_text}</dd>
                                                            </>
                                                        ) : null}
                                                        {detailEntry.utilizationTradeSite && detailEntry.utilizationTradeSite.length > 0 ? (
                                                            <>
                                                                <dt className="col-6">Grundstückskategorie:</dt>
                                                                <dd className="col-6">{detailEntry.utilizationTradeSite_text}</dd>
                                                            </>
                                                        ) : null}
                                                    </dl>
                                                    {/* End Row */}
                                                </div>
                                                {/* End Col */}
                                                <div className="col-md-6">
                                                    <dl className="row">
                                                        {detailEntry.numberOfRooms && detailEntry.numberOfRooms > 0 ? (
                                                            <>
                                                                <dt className="col-6">Zimmer:</dt>
                                                                <dd className="col-6">{toNumber(detailEntry.numberOfRooms)}</dd>
                                                            </>
                                                        ) : null}
                                                        {detailEntry.numberOfBedRooms && detailEntry.numberOfBedRooms > 0 ? (
                                                            <>
                                                                <dt className="col-6">Schlafzimmer:</dt>
                                                                <dd className="col-6">{toNumber(detailEntry.numberOfBedRooms)}</dd>
                                                            </>
                                                        ) : null}
                                                        {detailEntry.numberOfBathRooms && detailEntry.numberOfBathRooms > 0 ? (
                                                            <>
                                                                <dt className="col-6">Badezimmer:</dt>
                                                                <dd className="col-6">{toNumber(detailEntry.numberOfBathRooms)}</dd>
                                                            </>
                                                        ) : null}
                                                        {detailEntry.parkingSpaceType && detailEntry.parkingSpaceType.length > 0 ? (
                                                            <>
                                                                <dt className="col-6">Parkplatz:</dt>
                                                                <dd className="col-6">{detailEntry.parkingSpaceType_text}</dd>
                                                            </>
                                                        ) : null}
                                                        {detailEntry.numberOfParkingSpaces && detailEntry.numberOfParkingSpaces > 0 ? (
                                                            <>
                                                                <dt className="col-6">Anzahl Stellplätze:</dt>
                                                                <dd className="col-6">{toNumber(detailEntry.numberOfParkingSpaces)}</dd>
                                                            </>
                                                        ) : null}
                                                        {detailEntry.siteDevelopmentType && detailEntry.siteDevelopmentType.length > 0 ? (
                                                            <>
                                                                <dt className="col-6">Erschließung:</dt>
                                                                <dd className="col-6">{detailEntry.siteDevelopmentType_text}</dd>
                                                            </>
                                                        ) : null}
                                                        {detailEntry.siteConstructibleType && detailEntry.siteConstructibleType.length > 0 ? (
                                                            <>
                                                                <dt className="col-6">Bebaubar nach:</dt>
                                                                <dd className="col-6">{detailEntry.siteConstructibleType_text}</dd>
                                                            </>
                                                        ) : null}
                                                        {detailEntry.distanceToMRS && detailEntry.distanceToMRS > 0 ? (
                                                            <>
                                                                <dt className="col-6">Fahrzeit zum nächsten Bahnhof:</dt>
                                                                <dd className="col-6">{toNumber(detailEntry.distanceToMRS)} Minuten</dd>
                                                            </>
                                                        ) : null}
                                                        {detailEntry.distanceToFM && detailEntry.distanceToFM > 0 ? (
                                                            <>
                                                                <dt className="col-6">Fahrzeit zur nächsten Autobahn:</dt>
                                                                <dd className="col-6">{toNumber(detailEntry.distanceToFM)} Minuten</dd>
                                                            </>
                                                        ) : null}
                                                        {detailEntry.distanceToPT && detailEntry.distanceToPT > 0 ? (
                                                            <>
                                                                <dt className="col-6">Laufzeit zum öffentlichen Nahverkehr:</dt>
                                                                <dd className="col-6">{toNumber(detailEntry.distanceToPT)} Minuten</dd>
                                                            </>
                                                        ) : null}
                                                        {detailEntry.distanceToAirport && detailEntry.distanceToAirport > 0 ? (
                                                            <>
                                                                <dt className="col-6">Fahrzeit zum nächsten Flughafen:</dt>
                                                                <dd className="col-6">{toNumber(detailEntry.distanceToAirport)} Minuten</dd>
                                                            </>
                                                        ) : null}
                                                    </dl>
                                                    {/* End Row */}
                                                </div>
                                                {/* End Col */}
                                            </div>
                                            {/* End Row */}
                                        </>
                                    ) : null}

                                    <hr className="my-6" />
                                    <div className="mb-4">
                                        <h4>Kosten</h4>
                                    </div>

                                    {detailEntryExists ? (
                                        <div className="row justify-content-md-between">
                                            <div className="col-md-6">
                                                <dl className="row">
                                                    {detailEntry.value && detailEntry.value > 0 ? (
                                                        <>
                                                            <dt className="col-6">Kaufpreis:</dt>
                                                            <dd className="col-6">{currency(detailEntry.value)}</dd>
                                                        </>
                                                    ) : null}
                                                    {detailEntry.parkingSpacePrice &&
                                                    detailEntry.parkingSpacePrice > 0 &&
                                                    (detailEntry.realEstateType === 'realestates.houseBuy' || detailEntry.realEstateType === 'realestates.apartmentBuy') ? (
                                                        <>
                                                            <dt className="col-6">Garage/ Stellplatz-Kaufpreis:</dt>
                                                            <dd className="col-6">{currency(detailEntry.parkingSpacePrice)}</dd>
                                                        </>
                                                    ) : null}
                                                    {detailEntry.baseRent && detailEntry.baseRent > 0 ? (
                                                        <>
                                                            <dt className="col-6">Kaltmiete:</dt>
                                                            <dd className="col-6">{currency(detailEntry.baseRent)}</dd>
                                                        </>
                                                    ) : null}
                                                    {detailEntry.serviceCharge && detailEntry.serviceCharge > 0 ? (
                                                        <>
                                                            <dt className="col-6">Nebenkosten:</dt>
                                                            <dd className="col-6">{currency(detailEntry.serviceCharge)}</dd>
                                                        </>
                                                    ) : null}
                                                    {detailEntry.heatingCosts && detailEntry.heatingCosts > 0 ? (
                                                        <>
                                                            <dt className="col-6">Heizkosten:</dt>
                                                            <dd className="col-6">{currency(detailEntry.heatingCosts)}</dd>
                                                        </>
                                                    ) : null}
                                                    {detailEntry.totalRent && detailEntry.totalRent > 0 ? (
                                                        <>
                                                            <dt className="col-6">Gesamtmiete:</dt>
                                                            <dd className="col-6">{currency(detailEntry.totalRent)}</dd>
                                                        </>
                                                    ) : null}
                                                    {detailEntry.additionalCosts && detailEntry.additionalCosts > 0 ? (
                                                        <>
                                                            <dt className="col-6">Nebenkosten:</dt>
                                                            <dd className="col-6">{currency(detailEntry.additionalCosts)}</dd>
                                                        </>
                                                    ) : null}
                                                </dl>
                                            </div>
                                            {/* End Col */}
                                            <div className="col-md-6">
                                                <dl className="row">
                                                    {detailEntry.courtage && detailEntry.courtage.length > 0 ? (
                                                        <>
                                                            <dt className="col-6">Provision für Käufer:</dt>
                                                            <dd className="col-6">
                                                                {detailEntry.courtage} {detailEntry.courtageNote && detailEntry.courtageNote.length > 0 ? detailEntry.courtageNote : null}
                                                            </dd>
                                                        </>
                                                    ) : null}
                                                    {detailEntry.rentalIncome && detailEntry.rentalIncome > 0 ? (
                                                        <>
                                                            <dt className="col-6">Mieteinnahmen pro Monat:</dt>
                                                            <dd className="col-6">{currency(detailEntry.rentalIncome)}</dd>
                                                        </>
                                                    ) : null}
                                                    {detailEntry.deposit && detailEntry.deposit > 0 ? (
                                                        <>
                                                            <dt className="col-6">Kaution o. Genossenschafts­anteile:</dt>
                                                            <dd className="col-6">{currency(detailEntry.deposit)}</dd>
                                                        </>
                                                    ) : null}
                                                    {detailEntry.parkingSpacePrice &&
                                                    detailEntry.parkingSpacePrice > 0 &&
                                                    (detailEntry.realEstateType === 'realestates.houseRent' ||
                                                        detailEntry.realEstateType === 'realestates.apartmentRent' ||
                                                        detailEntry.realEstateType === 'realestates.office') ? (
                                                        <>
                                                            <dt className="col-6">Garage/ Stellplatz-Kaufpreis:</dt>
                                                            <dd className="col-6">{currency(detailEntry.parkingSpacePrice)}</dd>
                                                        </>
                                                    ) : null}
                                                </dl>
                                                {/* End Row */}
                                            </div>
                                            {/* End Col */}
                                        </div>
                                    ) : null}
                                    {detailEntryExists && detailEntry.realEstateType !== 'realestates.livingBuySite' && detailEntry.realEstateType !== 'realestates.tradeSite' ? (
                                        <>
                                            <hr className="my-6" />
                                            <div className="mb-4">
                                                <h4>Bausubstanz und Energieausweis</h4>
                                            </div>
                                            {detailEntryExists ? (
                                                <div className="row justify-content-md-between">
                                                    <div className="col-md-6">
                                                        <dl className="row">
                                                            {detailEntry.constructionYear && detailEntry.constructionYear > 0 ? (
                                                                <>
                                                                    <dt className="col-6">Baujahr:</dt>
                                                                    <dd className="col-6">{detailEntry.constructionYear}</dd>
                                                                </>
                                                            ) : null}
                                                            {detailEntry.constructionPhase && detailEntry.constructionPhase.length > 0 ? (
                                                                <>
                                                                    <dt className="col-6">Bauphase:</dt>
                                                                    <dd className="col-6">{detailEntry.constructionPhase_text}</dd>
                                                                </>
                                                            ) : null}
                                                            {detailEntry.lastRefurbishment && detailEntry.lastRefurbishment > 0 ? (
                                                                <>
                                                                    <dt className="col-6">Modernisierung / Sanierung:</dt>
                                                                    <dd className="col-6">{detailEntry.lastRefurbishment}</dd>
                                                                </>
                                                            ) : null}
                                                            {detailEntry.condition && detailEntry.condition.length > 0 ? (
                                                                <>
                                                                    <dt className="col-6">Objektzustand:</dt>
                                                                    <dd className="col-6">{detailEntry.condition_text}</dd>
                                                                </>
                                                            ) : null}
                                                            {detailEntry.lodgerFlat_boolean ? (
                                                                <>
                                                                    <dt className="col-6">Mit Einliegerwohnung:</dt>
                                                                    <dd className="col-6">Ja</dd>
                                                                </>
                                                            ) : null}
                                                        </dl>
                                                        {/* End Row */}
                                                    </div>
                                                    {/* End Col */}
                                                    <div className="col-md-6">
                                                        <dl className="row">
                                                            {detailEntry.interiorQuality && detailEntry.interiorQuality.length > 0 ? (
                                                                <>
                                                                    <dt className="col-6">Ausstattung:</dt>
                                                                    <dd className="col-6">{detailEntry.interiorQuality_text}</dd>
                                                                </>
                                                            ) : null}
                                                            {detailEntry.heatingTypeEnev2014 && detailEntry.heatingTypeEnev2014.length > 0 ? (
                                                                <>
                                                                    <dt className="col-6">Heizungsart:</dt>
                                                                    <dd className="col-6">{detailEntry.heatingTypeEnev2014_text}</dd>
                                                                </>
                                                            ) : null}
                                                            {detailEntry.firingType && detailEntry.firingType.length > 0 ? (
                                                                <>
                                                                    <dt className="col-6">Wesentliche Energieträger:</dt>
                                                                    <dd className="col-6">{detailEntry.firingType_text}</dd>
                                                                </>
                                                            ) : null}
                                                            {detailEntry.buildingEnergyRatingType && detailEntry.buildingEnergyRatingType.length > 0 ? (
                                                                <>
                                                                    <dt className="col-6">Energie­ausweistyp:</dt>
                                                                    <dd className="col-6">
                                                                        {detailEntry.buildingEnergyRatingType_text === 'Energieverbrauchskennwert'
                                                                            ? 'Energie-Verbrauchskennwert'
                                                                            : detailEntry.buildingEnergyRatingType_text}
                                                                    </dd>
                                                                </>
                                                            ) : null}
                                                            {detailEntry.thermalCharacteristic && detailEntry.thermalCharacteristic > 0 ? (
                                                                <>
                                                                    <dt className="col-6">Verbrauchskennwert:</dt>
                                                                    <dd className="col-6">{toNumber(detailEntry.thermalCharacteristic)} kWh/(m²*a)</dd>
                                                                </>
                                                            ) : null}
                                                        </dl>
                                                        {/* End Row */}
                                                    </div>
                                                    {/* End Col */}
                                                </div>
                                            ) : null}
                                        </>
                                    ) : null}
                                    <hr className="my-6" />
                                    <div className="mb-4">
                                        <h4>Objektbeschreibung</h4>
                                    </div>
                                    <ReactMarkdown
                                        className="text-wrap markdown-body"
                                        children={
                                            detailEntry && detailEntry.descriptionNoteLong && detailEntry.descriptionNoteLong.length > 0
                                                ? detailEntry.descriptionNoteLong
                                                : detailEntry && detailEntry.descriptionNote && detailEntry.descriptionNote.length > 0
                                                ? detailEntry.descriptionNote
                                                : ''
                                        }
                                        remarkPlugins={[remarkGfm]}
                                    />
                                    {/* Collapse Link - Content */}
                                    <p></p>
                                    <div className="collapse" id="propertyOverviewDescriptionViewMoreCollapse">
                                        {detailEntry && detailEntry.descriptionNoteLong && detailEntry.descriptionNoteLong.length > 0 ? null : (
                                            <>
                                                <hr className="my-6" />
                                                <div className="mb-4">
                                                    <h4>Ausstattung</h4>
                                                </div>
                                                <ReactMarkdown
                                                    className="text-wrap markdown-body"
                                                    children={
                                                        detailEntry && detailEntry.furnishingNoteLong && detailEntry.furnishingNoteLong.length > 0
                                                            ? detailEntry.furnishingNoteLong
                                                            : detailEntry && detailEntry.furnishingNote && detailEntry.furnishingNote.length > 0
                                                            ? detailEntry.furnishingNote
                                                            : ''
                                                    }
                                                    remarkPlugins={[remarkGfm]}
                                                />
                                            </>
                                        )}
                                        <hr className="my-6" />
                                        <div className="mb-4">
                                            <h4>Lage</h4>
                                        </div>
                                        <ReactMarkdown
                                            className="text-wrap markdown-body"
                                            children={
                                                detailEntry && detailEntry.locationNoteLong && detailEntry.locationNoteLong.length > 0
                                                    ? detailEntry.locationNoteLong
                                                    : detailEntry && detailEntry.locationNote && detailEntry.locationNote.length > 0
                                                    ? detailEntry.locationNote
                                                    : ''
                                            }
                                            remarkPlugins={[remarkGfm]}
                                        />
                                        <hr className="my-6" />
                                        <div className="mb-4">
                                            <h4>Sonstiges</h4>
                                        </div>
                                        <ReactMarkdown
                                            className="text-wrap markdown-body"
                                            children={
                                                detailEntry && detailEntry.otherNoteLong && detailEntry.otherNoteLong.length > 0
                                                    ? detailEntry.otherNoteLong
                                                    : detailEntry && detailEntry.otherNote && detailEntry.otherNote.length > 0
                                                    ? detailEntry.otherNote
                                                    : ''
                                            }
                                            remarkPlugins={[remarkGfm]}
                                        />
                                        <p></p>
                                    </div>
                                    {/* End Collapse Link - Content */}
                                    {/* Collapse Link */}
                                    <a
                                        className="link link-collapse"
                                        data-bs-toggle="collapse"
                                        href="#propertyOverviewDescriptionViewMoreCollapse"
                                        role="button"
                                        aria-expanded="false"
                                        aria-controls="propertyOverviewDescriptionViewMoreCollapse"
                                    >
                                        <span className="link-collapse-default">Mehr anzeigen</span>
                                        <span className="link-collapse-active">Weniger anzeigen</span>
                                    </a>
                                    {/* End Collapse Link */}
                                    {/* <hr className="my-6" />
                                    <div className="mb-4">
                                        <h4>Bewertung</h4>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <ul className="list-unstyled list-py-1 mb-0">
                                                <li className="d-flex align-items-center">
                                                    <h6 className="mb-0">Ort</h6>
                                                    <div className="d-flex gap-1 ms-auto">
                                                        <img src={`${process.env.PUBLIC_URL + '/assets/svg/illustrations/star.svg'}`} alt="Review rating" width={16} />
                                                        <img src={`${process.env.PUBLIC_URL + '/assets/svg/illustrations/star.svg'}`} alt="Review rating" width={16} />
                                                        <img src={`${process.env.PUBLIC_URL + '/assets/svg/illustrations/star.svg'}`} alt="Review rating" width={16} />
                                                        <img src={`${process.env.PUBLIC_URL + '/assets/svg/illustrations/star.svg'}`} alt="Review rating" width={16} />
                                                        <img src={`${process.env.PUBLIC_URL + '/assets/svg/illustrations/star.svg'}`} alt="Review rating" width={16} />
                                                    </div>
                                                </li>
                                                <li className="d-flex align-items-center">
                                                    <h6 className="mb-0">Bauhjahr</h6>
                                                    <div className="d-flex gap-1 ms-auto">
                                                        <img src={`${process.env.PUBLIC_URL + '/assets/svg/illustrations/star.svg'}`} alt="Review rating" width={16} />
                                                        <img src={`${process.env.PUBLIC_URL + '/assets/svg/illustrations/star.svg'}`} alt="Review rating" width={16} />
                                                        <img src={`${process.env.PUBLIC_URL + '/assets/svg/illustrations/star.svg'}`} alt="Review rating" width={16} />
                                                        <img src={`${process.env.PUBLIC_URL + '/assets/svg/illustrations/star.svg'}`} alt="Review rating" width={16} />
                                                        <img src={`${process.env.PUBLIC_URL + '/assets/svg/illustrations/star-half.svg'}`} alt="Review rating" width={16} />
                                                    </div>
                                                </li>
                                                <li className="d-flex align-items-center">
                                                    <h6 className="mb-0">Schulnähe</h6>
                                                    <div className="d-flex gap-1 ms-auto">
                                                        <img src={`${process.env.PUBLIC_URL + '/assets/svg/illustrations/star.svg'}`} alt="Review rating" width={16} />
                                                        <img src={`${process.env.PUBLIC_URL + '/assets/svg/illustrations/star.svg'}`} alt="Review rating" width={16} />
                                                        <img src={`${process.env.PUBLIC_URL + '/assets/svg/illustrations/star.svg'}`} alt="Review rating" width={16} />
                                                        <img src={`${process.env.PUBLIC_URL + '/assets/svg/illustrations/star-half.svg'}`} alt="Review rating" width={16} />
                                                        <img src={`${process.env.PUBLIC_URL + '/assets/svg/illustrations/star-muted.svg'}`} alt="Review rating" width={16} />
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="col-sm-6">
                                            <ul className="list-unstyled list-py-1 mb-0">
                                                <li className="d-flex align-items-center">
                                                    <h6 className="mb-0">Kosten</h6>
                                                    <div className="d-flex gap-1 ms-auto">
                                                        <img src={`${process.env.PUBLIC_URL + '/assets/svg/illustrations/star.svg'}`} alt="Review rating" width={16} />
                                                        <img src={`${process.env.PUBLIC_URL + '/assets/svg/illustrations/star.svg'}`} alt="Review rating" width={16} />
                                                        <img src={`${process.env.PUBLIC_URL + '/assets/svg/illustrations/star.svg'}`} alt="Review rating" width={16} />
                                                        <img src={`${process.env.PUBLIC_URL + '/assets/svg/illustrations/star.svg'}`} alt="Review rating" width={16} />
                                                        <img src={`${process.env.PUBLIC_URL + '/assets/svg/illustrations/star.svg'}`} alt="Review rating" width={16} />
                                                    </div>
                                                </li>
                                                <li className="d-flex align-items-center">
                                                    <h6 className="mb-0">Energieeffizienz</h6>
                                                    <div className="d-flex gap-1 ms-auto">
                                                        <img src={`${process.env.PUBLIC_URL + '/assets/svg/illustrations/star.svg'}`} alt="Review rating" width={16} />
                                                        <img src={`${process.env.PUBLIC_URL + '/assets/svg/illustrations/star.svg'}`} alt="Review rating" width={16} />
                                                        <img src={`${process.env.PUBLIC_URL + '/assets/svg/illustrations/star.svg'}`} alt="Review rating" width={16} />
                                                        <img src={`${process.env.PUBLIC_URL + '/assets/svg/illustrations/star.svg'}`} alt="Review rating" width={16} />
                                                        <img src={`${process.env.PUBLIC_URL + '/assets/svg/illustrations/star-muted.svg'}`} alt="Review rating" width={16} />
                                                    </div>
                                                </li>
                                                <li className="d-flex align-items-center">
                                                    <h6 className="mb-0">Werthaltigkeit</h6>
                                                    <div className="d-flex gap-1 ms-auto">
                                                        <img src={`${process.env.PUBLIC_URL + '/assets/svg/illustrations/star.svg'}`} alt="Review rating" width={16} />
                                                        <img src={`${process.env.PUBLIC_URL + '/assets/svg/illustrations/star.svg'}`} alt="Review rating" width={16} />
                                                        <img src={`${process.env.PUBLIC_URL + '/assets/svg/illustrations/star.svg'}`} alt="Review rating" width={16} />
                                                        <img src={`${process.env.PUBLIC_URL + '/assets/svg/illustrations/star-muted.svg'}`} alt="Review rating" width={16} />
                                                        <img src={`${process.env.PUBLIC_URL + '/assets/svg/illustrations/star-muted.svg'}`} alt="Review rating" width={16} />
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <hr className="my-6" />
                                    <h4 className="mb-1">Geschätzte laufende Kosten</h4>
                                    <p className="small">Basierend auf Schätzungen</p>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <span className="h1">1.550,00 €</span>
                                            <p>Geschätzte monatliche Kosten</p>
                                        </div>
                                        <div className="col-md-6">
                                            <dl className="row">
                                                <dt className="col-6">
                                                    <i className="bi-bank2 me-2" /> Kreditrate
                                                </dt>
                                                <dd className="col-6 text-end">1.400 € p/m</dd>
                                                <dt className="col-6">
                                                    <i className="bi-lightning-charge-fill me-2" /> Strom
                                                </dt>
                                                <dd className="col-6 text-end">ca. 80 € p/m</dd>
                                                <dt className="col-6">
                                                    <i className="bi-droplet-half me-2" /> Wasser
                                                </dt>
                                                <dd className="col-6 text-end">ca. 70 € p/m</dd>
                                                <dt className="col-6 text-dark">
                                                    <i className="bi-shield-shaded me-2" /> Versicherungen
                                                </dt>
                                                <dd className="col-6 text-end">keine Angaben</dd>
                                            </dl>
                                        </div>
                                    </div> */}
                                </div>
                                <div className="tab-pane fade" id="propertyOverviewNavTwo" role="tabpanel" aria-labelledby="propertyOverviewNavTwo-tab">
                                    {detailEntryExists
                                        ? grundrissTmp.map((grundrissUrl: any, index: number) => {
                                              return (
                                                  <div
                                                      onClick={() => {
                                                          setTogglerGrundriss(!togglerGrundriss);
                                                          setGrundrissSlide(index + 1);
                                                      }}
                                                      data-fslightbox="propertyOverviewFloorplan"
                                                      style={{ cursor: 'pointer' }}
                                                  >
                                                      <img className="img-fluid w-100" src={`${grundrissUrl}`} alt="Image Description" />
                                                  </div>
                                              );
                                          })
                                        : null}
                                </div>
                                <FsLightbox
                                    toggler={togglerGrundriss}
                                    sources={grundrissTmp}
                                    slide={grundrissSlide}
                                    /*
                                    // @ts-ignore */
                                    captions={grundrissCaptions}
                                />
                                {/* <div className="tab-pane fade" id="propertyOverviewNavThree" role="tabpanel" aria-labelledby="propertyOverviewNavThree-tab">
                                    
                                    <div
                                        id="map"
                                        className="h-380rem"
                                        data-hs-leaflet-options='{
                                            "map": {
                                                "scrollWheelZoom": false,
                                                "coords": [37.4040344, -122.0289704]
                                            },
                                            "marker": [
                                                {
                                                "coords": [37.4040344, -122.0289704],
                                                "icon": {
                                                    "iconUrl": "%PUBLIC_URL%/assets/svg/components/map-pin.svg",
                                                    "iconSize": [50, 45]
                                                },
                                                "popup": {
                                                    "text": "153 Williamson Plaza, Maggieberg",
                                                    "title": "Address"
                                                }
                                                }
                                            ]
                                            }'
                                    />
                                </div>
                                <div className="tab-pane fade" id="propertyOverviewNavFour" role="tabpanel" aria-labelledby="propertyOverviewNavFour-tab">
                                    <div className="mb-5">
                                        <h5>Sale activity</h5>
                                        <p>Average estimated value for a flat in HG2:</p>
                                        <h3 className="text-primary mb-0">£271,401</h3>
                                        <i className="bi-arrow-down-right text-danger" />
                                        <span>£7,710 (-2.762%)</span>
                                        <span className="text-muted small ms-1">Over the last 12 months</span>
                                    </div>
                                    <div className="mb-5">
                                        <h5>In the last 12 months</h5>
                                        <div className="row justify-content-sm-between">
                                            <div className="col-sm-6">
                                                <span className="d-block">Average sale price</span>
                                                <h3 className="text-primary mb-0">£267,606</h3>
                                            </div>
                                            <div className="col-sm-6">
                                                <span className="d-block">Properties sold</span>
                                                <h3 className="text-primary mb-0">51</h3>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                            {/* End Tab Content */}
                            <hr className="my-6" />
                            <div className="mb-4">
                                <h4>Kontaktpersonen</h4>
                            </div>
                            <div className="row">
                                <div className="col-sm-12 col-md-12 mb-4 mb-sm-0">
                                    {/* Media */}
                                    <div className="d-flex">
                                        <div className="flex-shrink-0">
                                            <img className="avatar avatar-lg avatar-circle" src={`${process.env.PUBLIC_URL + '/img/katrinrauprecht.png'}`} alt="Image Description" />
                                        </div>
                                        <div className="flex-grow-1 ms-3">
                                            <h4 className="mb-1">
                                                <a className="text-dark" href="mailto:info@rauprecht-immobilien.de">
                                                    Katrin Rauprecht
                                                </a>
                                            </h4>
                                            <span className="d-block small mb-1">
                                                <a href="tel:+493338768439">
                                                    <i className="bi-telephone-fill me-1" /> 03338 / 768439
                                                </a>
                                            </span>
                                            <a className="link" href="mailto:info@rauprecht-immobilien.de">
                                                <i className="bi-envelope-fill me-1" /> info@rauprecht-immobilien.de
                                            </a>
                                        </div>
                                    </div>
                                    {/* End Media */}
                                </div>
                                {/* End Col */}
                                {/* End Col */}
                            </div>
                            {/* End Row */}
                        </div>
                        {/* End Col */}
                        {!passcodeCorrect && !requestSuccessful ? (
                            <div className={`${requestNextStep ? 'col-lg-12' : 'col-lg-4'}`}>
                                {/* Sticky Block */}
                                <div id="stickyBlockStartPointEg2">
                                    <div
                                        className="js-sticky-block"
                                        data-hs-sticky-block-options='{
                          "parentSelector": "#stickyBlockStartPointEg2",
                          "breakpoint": "lg",
                          "startPoint": "#stickyBlockStartPointEg2",
                          "endPoint": "#stickyBlockEndPointEg2",
                          "stickyOffsetTop": 24,
                          "stickyOffsetBottom": 0
                          }'
                                    >
                                        {/* Card */}
                                        {passcodeLength && passcodeLength > 1 && !requestNextStep ? (
                                            <div className="card card-bordered mb-2">
                                                <div className="card-body pt-3 pb-2">
                                                    <div className="mb-2">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Passcode"
                                                            aria-label="Passcode"
                                                            onChange={(e) => {
                                                                if (e.target.value.length === passcodeLength) {
                                                                    setPasscodeManual(true);
                                                                    benit_homepage_get_detail_entry(itemId, e.target.value);
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                {/* End Card */}
                                            </div>
                                        ) : null}
                                        <div className="card card-bordered">
                                            <div className="card-body">
                                                {/* Media */}
                                                {!requestNextStep ? (
                                                    <>
                                                        <div className="d-flex align-items-center mb-4">
                                                            <div className="flex-shrink-0">
                                                                <img className="avatar avatar-circle" src={`${process.env.PUBLIC_URL + '/img/katrinrauprecht.png'}`} alt="Image Description" />
                                                            </div>
                                                            <div className="flex-grow-1 ms-3">
                                                                <h4 className="card-title">Fragen Sie uns nach weiteren Informationen</h4>
                                                            </div>
                                                        </div>
                                                        {/* End Media */}
                                                        {/* Form */}
                                                        <form>
                                                            <select
                                                                className="form-select mb-2"
                                                                onChange={(e: any) => setRequest({ ...request, anrede: e.target.value })}
                                                                value={request && request.anrede ? request.anrede : 'Frau'}
                                                            >
                                                                <option value="Anrede">Anrede</option>
                                                                <option value="Frau">Frau</option>
                                                                <option value="Herr">Herr</option>
                                                            </select>
                                                            <div className="mb-2">
                                                                <label htmlFor="fullNamePropertyOverviewContactForm" className="form-label visually-hidden">
                                                                    Vorname
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="fullNamePropertyOverviewContactForm"
                                                                    placeholder="Vorname"
                                                                    aria-label="Vorname"
                                                                    value={request && request.firstName ? request.firstName : ''}
                                                                    onChange={(e: any) => setRequest({ ...request, firstName: e.target.value })}
                                                                />
                                                            </div>
                                                            <div className="mb-2">
                                                                <label htmlFor="fullNamePropertyOverviewContactForm" className="form-label visually-hidden">
                                                                    Nachname
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="fullNamePropertyOverviewContactForm"
                                                                    placeholder="Nachname"
                                                                    aria-label="Nachname"
                                                                    value={request.lastName ? request.lastName : ''}
                                                                    onChange={(e: any) => setRequest({ ...request, lastName: e.target.value })}
                                                                />
                                                            </div>
                                                            <div className="mb-2">
                                                                <label htmlFor="fullNamePropertyOverviewContactForm" className="form-label visually-hidden">
                                                                    Straße und Hausnummer
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="fullNamePropertyOverviewContactForm"
                                                                    placeholder="Straße und Hausnummer"
                                                                    aria-label="Strasse"
                                                                    value={request.street ? request.street : ''}
                                                                    onChange={(e: any) => setRequest({ ...request, street: e.target.value })}
                                                                />
                                                            </div>
                                                            <div className="mb-2">
                                                                <label htmlFor="fullNamePropertyOverviewContactForm" className="form-label visually-hidden">
                                                                    Postleitzahl
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="fullNamePropertyOverviewContactForm"
                                                                    placeholder="Postleitzahl"
                                                                    aria-label="Postleitzahl"
                                                                    value={request.postcode ? request.postcode : ''}
                                                                    onChange={(e: any) => setRequest({ ...request, postcode: e.target.value })}
                                                                />
                                                            </div>
                                                            <div className="mb-2">
                                                                <label htmlFor="fullNamePropertyOverviewContactForm" className="form-label visually-hidden">
                                                                    Ort
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="fullNamePropertyOverviewContactForm"
                                                                    placeholder="Ort"
                                                                    aria-label="Ort"
                                                                    value={request.city ? request.city : ''}
                                                                    onChange={(e: any) => setRequest({ ...request, city: e.target.value })}
                                                                />
                                                            </div>
                                                            <div className="mb-2">
                                                                <label htmlFor="emailPropertyOverviewContactForm" className="form-label visually-hidden">
                                                                    E-Mail
                                                                </label>
                                                                <input
                                                                    type="email"
                                                                    className="form-control"
                                                                    id="emailPropertyOverviewContactForm"
                                                                    placeholder="E-Mail"
                                                                    aria-label="E-Mail"
                                                                    value={request.eMail ? request.eMail : ''}
                                                                    onChange={(e: any) => setRequest({ ...request, eMail: e.target.value })}
                                                                />
                                                            </div>
                                                            <div className="mb-2">
                                                                <label htmlFor="phoneNumberPropertyOverviewContactForm" className="form-label visually-hidden">
                                                                    Telefon
                                                                </label>
                                                                <input
                                                                    type="tel"
                                                                    className="form-control"
                                                                    id="phoneNumberPropertyOverviewContactForm"
                                                                    placeholder="Telefon"
                                                                    aria-label="Telefon"
                                                                    value={request.telephone ? request.telephone : ''}
                                                                    onChange={(e: any) => setRequest({ ...request, telephone: e.target.value })}
                                                                />
                                                            </div>
                                                            <div className="mb-2">
                                                                <label htmlFor="questionPropertyOverviewContactForm" className="form-label visually-hidden">
                                                                    Anfrage
                                                                </label>
                                                                <textarea
                                                                    className="form-control"
                                                                    id="questionPropertyOverviewContactForm"
                                                                    placeholder="Ich hätte gern mehr Information über..."
                                                                    aria-label="Ich hätte gern mehr Information über..."
                                                                    rows={4}
                                                                    defaultValue={''}
                                                                    value={request.customText ? request.customText : ''}
                                                                    onChange={(e: any) => setRequest({ ...request, customText: e.target.value })}
                                                                />
                                                            </div>
                                                            <div className="d-grid">
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-primary"
                                                                    onClick={(e: any) => setRequestNextStep(true)}
                                                                    disabled={
                                                                        request &&
                                                                        (!request.telephone ||
                                                                            !request.eMail ||
                                                                            !request.lastName ||
                                                                            !request.firstName ||
                                                                            !request.street ||
                                                                            !request.postcode ||
                                                                            !request.city ||
                                                                            !request.anrede ||
                                                                            request.anrede === 'Anrede')
                                                                    }
                                                                >
                                                                    Weiter
                                                                </button>
                                                            </div>
                                                        </form>
                                                        {/* End Form */}
                                                    </>
                                                ) : (
                                                    <>
                                                        <div className="row">
                                                            <div className="d-flex align-items-center mb-4">
                                                                <div className="flex-shrink-0">
                                                                    <img className="avatar avatar-circle" src={`${process.env.PUBLIC_URL + '/img/katrinrauprecht.png'}`} alt="Image Description" />
                                                                </div>
                                                                <div className="flex-grow-1 ms-3">
                                                                    <h4 className="card-title">
                                                                        Bitte akzeptieren Sie unsere Provisionsvereinbarung. Nach der Bestätigung wird Ihre Anfrage an uns weitergeleitet.
                                                                    </h4>
                                                                </div>
                                                            </div>
                                                            <div className="row mb-4">
                                                                <div className="col-12">
                                                                    {/* Card */}
                                                                    <div className="card text-left">
                                                                        <div className="card-body">
                                                                            <h3 className="card-title">
                                                                                {request && request.anrede ? (request.anrede === 'Frau' ? 'Sehr geehrte Frau' : 'Sehr geehrter Herr') : null}{' '}
                                                                                {request && request.lastName ? request.lastName : null},
                                                                            </h3>
                                                                            <p className="card-text">
                                                                                <br />
                                                                                seit dem 23.12.2020 ist Ihre aktive Bestätigung der Provisionsvereinbarung mit uns (Rauprecht Immobilien GmbH)
                                                                                erforderlich, damit wir tätig werden können (z.B. Besichtigung mit Ihnen durchführen, weitere Informationen zukommen
                                                                                lassen etc.)
                                                                                <br />
                                                                                <br />
                                                                                Mit der Aktivierung des untenstehenden Button "Provisionsvereinbarung akzeptieren" stimmen Sie der nachfolgenden
                                                                                Provisionsvereinbarung der Rauprecht Immobilien GmbH zu der unten aufgeführten Immobilie zu.
                                                                                <br />
                                                                                <br />
                                                                                <b>Hinweis:</b> Die Provision wird nur dann fällig, wenn Sie das Objekt tatsächlich kaufen.
                                                                                <br />
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    {/* End Card */}
                                                                </div>
                                                            </div>
                                                            <div className="row mb-4">
                                                                <div className="col-12">
                                                                    {/* Card */}
                                                                    <div className="card text-left">
                                                                        <div className="card-body">
                                                                            <h3 className="card-title text-center">Provisionsvereinbarung der Rauprecht Immobilien GmbH</h3>
                                                                            <p className="card-text">
                                                                                <br />
                                                                                Wir werden nach erfolgter Bestätigung zu diesen Konditionen für Sie tätig:
                                                                                <br />
                                                                                <br />
                                                                                Rauprecht Immobilien GmbH erhält bei Abschluss eines durch ihn vermittelten notariell beurkundeten Kaufvertrages zu
                                                                                dieser Immobilie von Ihnen die unten angegebene Provision.
                                                                                <br />
                                                                                <br />
                                                                                <b>Hinweis:</b> Der Auftrag kommt nur zwischen Ihnen und der Rauprecht Immobilien GmbH zustande.
                                                                                <br />
                                                                                <br />
                                                                                Mit Bestätigung der Provisionsvereinbarung versichere ich als Interessent mit den unten angegebenen Kontaktdaten, die
                                                                                Vereinbarung abzuschließen.
                                                                                <br />
                                                                                <br />
                                                                                Mit Absenden Ihrer Bestätigung wird eine automatisierte E-Mail an die Rauprecht Immobilien GmbH und Sie in Kopie mit dem
                                                                                Inhalt dieser Provisionsvereinbarung gesendet. Wir können ab Übermittlung der Bestätigung dann für Sie tätig werden.
                                                                                <br />
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    {/* End Card */}
                                                                </div>
                                                            </div>
                                                            <div className="row mb-4">
                                                                <div className="col-12">
                                                                    {/* Card */}
                                                                    <div className="card text-left">
                                                                        <div className="card-body">
                                                                            <h3 className="card-title text-center">Daten zur Immobilie & Provisionsinformation</h3>
                                                                            <p className="card-text text-center">
                                                                                <br />
                                                                                <b>{detailEntryExists && detailEntry.title ? detailEntry.title : null}</b>
                                                                            </p>
                                                                            {detailEntryExists && detailEntry.pictures
                                                                                ? Object.entries(detailEntry.pictures).map((picture: any, index: number) =>
                                                                                      index === 0 ? (
                                                                                          <div className="p-2 text-center">
                                                                                              <img
                                                                                                  className="card-img"
                                                                                                  src={`https://${
                                                                                                      host === 'mockups.benit.cloud' ||
                                                                                                      host === 'rauprecht-immobilien.de' ||
                                                                                                      host === 'www.rauprecht-immobilien.de'
                                                                                                          ? 'benit.cloud'
                                                                                                          : 'benit.dev'
                                                                                                  }/api/ri/real-estate/objekt/files/${picture[0]}`}
                                                                                                  alt="Image Description"
                                                                                              />
                                                                                          </div>
                                                                                      ) : null
                                                                                  )
                                                                                : null}

                                                                            {detailEntryExists ? (
                                                                                <div className="row justify-content-md-between mt-4">
                                                                                    <div className="col-md-6">
                                                                                        <dl className="row">
                                                                                            {detailEntry.value && detailEntry.value > 0 ? (
                                                                                                <>
                                                                                                    <dt className="col-6">Kaufpreis:</dt>
                                                                                                    <dd className="col-6">{currency(detailEntry.value)}</dd>
                                                                                                </>
                                                                                            ) : null}
                                                                                            {detailEntry.parkingSpacePrice &&
                                                                                            detailEntry.parkingSpacePrice > 0 &&
                                                                                            (detailEntry.realEstateType === 'realestates.houseBuy' ||
                                                                                                detailEntry.realEstateType === 'realestates.apartmentBuy') ? (
                                                                                                <>
                                                                                                    <dt className="col-6">Garage/ Stellplatz-Kaufpreis:</dt>
                                                                                                    <dd className="col-6">{currency(detailEntry.parkingSpacePrice)}</dd>
                                                                                                </>
                                                                                            ) : null}
                                                                                            {detailEntry.baseRent && detailEntry.baseRent > 0 ? (
                                                                                                <>
                                                                                                    <dt className="col-6">Kaltmiete:</dt>
                                                                                                    <dd className="col-6">{currency(detailEntry.baseRent)}</dd>
                                                                                                </>
                                                                                            ) : null}
                                                                                            {detailEntry.serviceCharge && detailEntry.serviceCharge > 0 ? (
                                                                                                <>
                                                                                                    <dt className="col-6">Nebenkosten:</dt>
                                                                                                    <dd className="col-6">{currency(detailEntry.serviceCharge)}</dd>
                                                                                                </>
                                                                                            ) : null}
                                                                                            {detailEntry.heatingCosts && detailEntry.heatingCosts > 0 ? (
                                                                                                <>
                                                                                                    <dt className="col-6">Heizkosten:</dt>
                                                                                                    <dd className="col-6">{currency(detailEntry.heatingCosts)}</dd>
                                                                                                </>
                                                                                            ) : null}
                                                                                            {detailEntry.totalRent && detailEntry.totalRent > 0 ? (
                                                                                                <>
                                                                                                    <dt className="col-6">Gesamtmiete:</dt>
                                                                                                    <dd className="col-6">{currency(detailEntry.totalRent)}</dd>
                                                                                                </>
                                                                                            ) : null}
                                                                                            {detailEntry.additionalCosts && detailEntry.additionalCosts > 0 ? (
                                                                                                <>
                                                                                                    <dt className="col-6">Nebenkosten:</dt>
                                                                                                    <dd className="col-6">{currency(detailEntry.additionalCosts)}</dd>
                                                                                                </>
                                                                                            ) : null}
                                                                                        </dl>
                                                                                    </div>
                                                                                    {/* End Col */}
                                                                                    <div className="col-md-6">
                                                                                        <dl className="row">
                                                                                            {detailEntry.courtage && detailEntry.courtage.length > 0 ? (
                                                                                                <>
                                                                                                    <dt className="col-6">Adresse:</dt>
                                                                                                    <dd className="col-6">
                                                                                                        {detailEntry.realStreet
                                                                                                            ? detailEntry.realStreet
                                                                                                            : detailEntry.showAddress && detailEntry.showAddress
                                                                                                            ? detailEntry.street
                                                                                                            : null}{' '}
                                                                                                        {detailEntry.realHouseNumber
                                                                                                            ? ` ${detailEntry.realHouseNumber}, `
                                                                                                            : detailEntry.showAddress && detailEntry.showAddress
                                                                                                            ? ` ${detailEntry.houseNumber}, `
                                                                                                            : null}
                                                                                                        {detailEntry.postcode ? detailEntry.postcode : null}{' '}
                                                                                                        {detailEntry.city ? detailEntry.city : null}
                                                                                                        {!detailEntry.showAddress && !(detailEntry.realStreet && detailEntry.realHouseNumber)
                                                                                                            ? ' (Bitte kontaktieren Sie uns für die genaue Adresse)'
                                                                                                            : null}
                                                                                                    </dd>
                                                                                                </>
                                                                                            ) : null}
                                                                                            {detailEntry.courtage && detailEntry.courtage.length > 0 ? (
                                                                                                <>
                                                                                                    <dt className="col-6">Provision für Käufer:</dt>
                                                                                                    <dd className="col-6">{detailEntry.courtage}</dd>
                                                                                                </>
                                                                                            ) : null}
                                                                                            {detailEntry.courtageNote && detailEntry.courtageNote.length > 0 ? (
                                                                                                <>
                                                                                                    <dd className="col-12">{detailEntry.courtageNote}</dd>
                                                                                                </>
                                                                                            ) : null}
                                                                                            {detailEntry.rentalIncome && detailEntry.rentalIncome > 0 ? (
                                                                                                <>
                                                                                                    <dt className="col-6">Mieteinnahmen pro Monat:</dt>
                                                                                                    <dd className="col-6">{currency(detailEntry.rentalIncome)}</dd>
                                                                                                </>
                                                                                            ) : null}
                                                                                            {detailEntry.deposit && detailEntry.deposit > 0 ? (
                                                                                                <>
                                                                                                    <dt className="col-6">Kaution o. Genossenschafts­anteile:</dt>
                                                                                                    <dd className="col-6">{currency(detailEntry.deposit)}</dd>
                                                                                                </>
                                                                                            ) : null}
                                                                                            {detailEntry.parkingSpacePrice &&
                                                                                            detailEntry.parkingSpacePrice > 0 &&
                                                                                            (detailEntry.realEstateType === 'realestates.houseRent' ||
                                                                                                detailEntry.realEstateType === 'realestates.apartmentRent' ||
                                                                                                detailEntry.realEstateType === 'realestates.office') ? (
                                                                                                <>
                                                                                                    <dt className="col-6">Garage/ Stellplatz-Kaufpreis:</dt>
                                                                                                    <dd className="col-6">{currency(detailEntry.parkingSpacePrice)}</dd>
                                                                                                </>
                                                                                            ) : null}
                                                                                        </dl>
                                                                                        {/* End Row */}
                                                                                    </div>
                                                                                    {/* End Col */}
                                                                                </div>
                                                                            ) : null}
                                                                        </div>
                                                                    </div>
                                                                    {/* End Card */}
                                                                </div>
                                                            </div>
                                                            <div className="row mb-4">
                                                                <div className="col-12">
                                                                    {/* Card */}
                                                                    <div className="card text-left">
                                                                        <div className="card-body">
                                                                            <h3 className="card-title text-center">Unsere Kontaktdaten</h3>
                                                                            <div className="row justify-content-md-between mt-4">
                                                                                <div className="col-md-6">
                                                                                    <dl className="row">
                                                                                        <dt className="col-4">Firma:</dt>
                                                                                        <dd className="col-8">Rauprecht Immobilien GmbH</dd>
                                                                                        <dt className="col-4">Name:</dt>
                                                                                        <dd className="col-8">Frau Katrin Rauprecht</dd>
                                                                                    </dl>
                                                                                </div>
                                                                                {/* End Col */}
                                                                                <div className="col-md-6">
                                                                                    <dl className="row">
                                                                                        <dt className="col-4">Adresse:</dt>
                                                                                        <dd className="col-8">Dorfstr. 42, 16321 Bernau bei Berlin</dd>
                                                                                        <dt className="col-4">Telefon:</dt>
                                                                                        <dd className="col-8">03338 768439</dd>
                                                                                    </dl>
                                                                                    {/* End Row */}
                                                                                </div>
                                                                                {/* End Col */}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {/* End Card */}
                                                                </div>
                                                            </div>
                                                            <div className="row mb-4">
                                                                <div className="col-12">
                                                                    {/* Card */}
                                                                    <div className="card text-left">
                                                                        <div className="card-body">
                                                                            <h3 className="card-title text-center">Kontaktdaten des Interessenten</h3>
                                                                            <div className="row justify-content-md-between mt-4">
                                                                                <div className="col-md-6">
                                                                                    <dl className="row">
                                                                                        <dt className="col-4">Name:</dt>
                                                                                        <dd className="col-8">
                                                                                            {request && request.anrede ? request.anrede : null}{' '}
                                                                                            {request && request.firstName ? request.firstName : null}{' '}
                                                                                            {request && request.lastName ? request.lastName : null}
                                                                                        </dd>
                                                                                        <dt className="col-4">E-Mail:</dt>
                                                                                        <dd className="col-8">{request && request.eMail ? request.eMail : null}</dd>
                                                                                    </dl>
                                                                                </div>
                                                                                {/* End Col */}
                                                                                <div className="col-md-6">
                                                                                    <dl className="row">
                                                                                        <dt className="col-4">Telefon:</dt>
                                                                                        <dd className="col-8">{request && request.telephone ? request.telephone : null}</dd>
                                                                                        <dt className="col-4">Zusätzliche Info:</dt>
                                                                                        <dd className="col-8">{request && request.customText ? request.customText : null}</dd>
                                                                                    </dl>
                                                                                    {/* End Row */}
                                                                                </div>
                                                                                {/* End Col */}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {/* End Card */}
                                                                </div>
                                                            </div>
                                                            <div className="row mb-4">
                                                                <div className="col-12">
                                                                    {/* Card */}
                                                                    <div className="card text-left">
                                                                        <div className="card-body">
                                                                            <h3 className="card-title text-center">Widerrufsbelehrung des Anbieters</h3>
                                                                            <p className="card-text">
                                                                                <br />
                                                                                <div className="text-center mb-4">
                                                                                    Im Falle eines zustande kommenden Maklervertrages haben Verbraucher das folgende Widerrufsrecht
                                                                                </div>
                                                                                <h3 className="text-center mb-3">Widerrufsbelehrung</h3>
                                                                                <h4 className="text-center mb-3">Widerrufsrecht für Verbraucher</h4>
                                                                                Sie haben das Recht, binnen vierzehn Tagen ohne Angabe von Gründen den Maklervertrag zu widerrufen. Die Widerrufsfrist
                                                                                beträgt vierzehn Tage ab dem Tag des Vertragsabschlusses.
                                                                                <br />
                                                                                Um Ihr Widerrufsrecht auszuüben, müssen Sie uns [ Rauprecht Immobilien GmbH, Dorfstr. 42, 16321 Bernau bei Berlin,
                                                                                Fax-Nr.: 03338 319360, Tel-Nr.: 03338 768439, info@rauprecht-immobilien.de ] mittels einer eindeutigen Erklärung (z.B.
                                                                                ein mit der Post versandter Brief, Telefax oder E-Mail) über Ihren Entschluss, diesen Vertrag zu widerrufen,
                                                                                informieren. Sie können dafür das beigefügte Muster-Widerrufsformular verwenden, das jedoch nicht vorgeschrieben ist.
                                                                                <br />
                                                                                Zur Wahrung der Widerrufsfrist reicht es aus, dass Sie die Mitteilung über die Ausübung des Widerrufsrechts vor Ablauf
                                                                                der Widerrufsfrist absenden.
                                                                                <br />
                                                                                <h4 className="text-center mt-3 mb-3">Folgen des Widerrufs</h4>
                                                                                Wenn Sie diesen Vertrag widerrufen, haben wir Ihnen alle Zahlungen, die wir von Ihnen erhalten haben, einschließlich der
                                                                                Lieferkosten (mit Ausnahme der zusätzlichen Kosten, die sich daraus ergeben, dass Sie eine andere Art der Lieferung als
                                                                                die von uns angebotene, günstigste Standardlieferung gewählt haben), unverzüglich und spätestens binnen vierzehn Tagen
                                                                                ab dem Tag zurückzuzahlen, an dem die Mitteilung über Ihren Widerruf dieses Vertrags bei uns eingegangen ist. Für diese
                                                                                Rückzahlung verwenden wir dasselbe Zahlungsmittel, das Sie bei der ursprünglichen Transaktion eingesetzt haben, es sei
                                                                                denn, mit Ihnen wurde ausdrücklich etwas anderes vereinbart; in keinem Fall werden Ihnen wegen dieser Rückzahlung
                                                                                Entgelte berechnet.
                                                                                <br />
                                                                                Haben Sie verlangt, dass die Dienstleistungen während der Widerrufsfrist beginnen soll, so haben Sie uns einen
                                                                                angemessenen Betrag zu zahlen, der dem Anteil der bis zu dem Zeitpunkt, zu dem Sie uns von der Ausübung des
                                                                                Widerrufsrechts hinsichtlich dieses Vertrags unterrichten, bereits erbrachten Dienstleistungen im Vergleich zum
                                                                                Gesamtumfang der im Vertrag vorgesehenen Dienstleistung entspricht.
                                                                                <br />
                                                                                <h3 className="text-center mb-3 mt-4">ENDE DER WIDERRUFSBELEHRUNG</h3>
                                                                                <h3 className="text-center mt-3 mb-3">Muster-Widerrufsformular</h3>
                                                                                (Wenn Sie den Maklervertrag widerrufen wollen, dann füllen Sie bitte dieses Formular aus und senden Sie es zurück.)
                                                                                <br />
                                                                                <br />
                                                                                An
                                                                                <br />
                                                                                Rauprecht Immobilien GmbH
                                                                                <br />
                                                                                Dorfstr. 42
                                                                                <br />
                                                                                16321 Bernau bei Berlin
                                                                                <br />
                                                                                Fax-Nr.: 03338 319360
                                                                                <br />
                                                                                info@rauprecht-immobilien.de
                                                                                <br />
                                                                                <br />
                                                                                Hiermit widerrufe(n) ich/wir(*) den von mir/uns(*) abgeschlossenen Vertrag über den Kauf der folgenden Waren(*)/ die
                                                                                Erbringung der folgenden Dienstleistung (*)
                                                                                <br />
                                                                                <br />
                                                                                Bestellt am (*) / erhalten am (*)
                                                                                <br />
                                                                                Name des/der Verbraucher(s)
                                                                                <br />
                                                                                Anschrift des/der Verbraucher(s)
                                                                                <br />
                                                                                Unterschrift des/der Verbraucher(s) (nur bei Mitteilung auf Papier)
                                                                                <br />
                                                                                Datum
                                                                                <br />
                                                                                (*) Unzutreffendes streichen
                                                                                <br />
                                                                                <h3 className="text-center mb-3 mt-4">ENDE DES WIDERRUFSFORMULARS</h3>
                                                                                <h3 className="text-center mt-3 mb-3">Hinweis zum vorzeitigen Erlöschen des Widerrufsrechts</h3>
                                                                                Ihr Widerrufsrecht erlischt bei einem Vertrag zur Erbringung von Dienstleistungen vorzeitig, wenn wir die Dienstleistung
                                                                                vollständig erbracht haben und mit der Ausführung der Dienstleistung erst begonnen haben, nachdem Sie dazu Ihre
                                                                                ausdrückliche Zustimmung gegeben haben und gleichzeitig Ihre Kenntnis davon bestätigt haben, dass Sie Ihr Widerrufsrecht
                                                                                bei vollständiger Vertragserfüllung durch uns verlieren.
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    {/* End Card */}
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-12">
                                                                    <div className="mb-4">
                                                                        <label htmlFor="CVPrivacySwitch" className="form-label fw-bold">
                                                                            Provisionsvereinbarung akzeptieren
                                                                        </label>
                                                                        <div className="row">
                                                                            <div className="col">
                                                                                <label htmlFor="CVPrivacySwitch" className="form-label text-body">
                                                                                    Mit der Anforderung der Immobilienbewertung stimme ich der Provisionsvereinbarung von Rauprecht Immobilien GmbH zu.
                                                                                </label>
                                                                            </div>
                                                                            <div className="col-auto">
                                                                                {/* Form Switch */}
                                                                                <div className="form-check form-switch">
                                                                                    <input
                                                                                        className="form-check-input"
                                                                                        type="checkbox"
                                                                                        id="CVPrivacySwitch"
                                                                                        checked={request && request.courtageTermsAccepted ? true : false}
                                                                                        onChange={(e: any) => setRequest({ ...request, courtageTermsAccepted: e.target.checked })}
                                                                                    />
                                                                                </div>
                                                                                {/* End Form Switch */}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-12">
                                                                    {/* End Row */}
                                                                    <div className="mb-4">
                                                                        <label htmlFor="CVPrivacySwitch" className="form-label fw-bold">
                                                                            Datenschutz akzeptieren
                                                                        </label>
                                                                        <div className="row">
                                                                            <div className="col">
                                                                                <label htmlFor="CVPrivacySwitch" className="form-label text-body">
                                                                                    Mit der Anforderung der Immobilienbewertung stimme ich den{' '}
                                                                                    <Link
                                                                                        to="/datenschutz"
                                                                                        className={` link-sm link-dark ${location.pathname.startsWith('/datenschutz') ? 'active text-blue' : null}`}
                                                                                        target="_blank"
                                                                                    >
                                                                                        Datenschutzbestimmungen
                                                                                    </Link>{' '}
                                                                                    von Rauprecht Immobilien GmbH zu, sowie deren Nutzung meiner Daten wie in der Einwilligung beschrieben.
                                                                                </label>
                                                                            </div>
                                                                            <div className="col-auto">
                                                                                {/* Form Switch */}
                                                                                <div className="form-check form-switch">
                                                                                    <input
                                                                                        className="form-check-input"
                                                                                        type="checkbox"
                                                                                        id="CVPrivacySwitch"
                                                                                        checked={request && request.termsAccepted ? true : false}
                                                                                        onChange={(e: any) => setRequest({ ...request, termsAccepted: e.target.checked })}
                                                                                    />
                                                                                </div>
                                                                                {/* End Form Switch */}
                                                                            </div>
                                                                        </div>
                                                                        {/* End Row */}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="d-grid">
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-primary"
                                                                    onClick={(e: any) => {
                                                                        setRequestNextStep(false);
                                                                        ri_homepage_send_request(
                                                                            request.anrede,
                                                                            request.firstName,
                                                                            request.lastName,
                                                                            request.eMail,
                                                                            request.telephone,
                                                                            request.customText,
                                                                            detailEntry.externalId,
                                                                            'objectRequest',
                                                                            request.street,
                                                                            request.postcode,
                                                                            request.city
                                                                        );
                                                                    }}
                                                                    disabled={request && (!request.termsAccepted || !request.courtageTermsAccepted)}
                                                                >
                                                                    Anfrage absenden
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                            {/* End Card */}
                                        </div>
                                    </div>
                                </div>
                                {/* End Sticky Block */}
                            </div>
                        ) : null}
                        {/* End Col */}
                    </div>
                    {/* End Row */}
                    {/* Sticky Block End Point */}
                    <div id="stickyBlockEndPointEg2" />
                    <hr className="my-6" />
                </div>
                {/* End Content */}
            </div>
            {/* Toast */}
            <div id="liveToast" className="position-fixed toast hide" role="alert" aria-live="assertive" aria-atomic="true" style={{ top: 20, right: 20, zIndex: 1000 }}>
                <div className="toast-header">
                    <div className="d-flex align-items-center flex-grow-1">
                        <div className="flex-shrink-0">
                            <img className="avatar avatar-sm avatar-circle" src="../assets/img/160x160/img4.jpg" alt="Image description" />
                        </div>
                        <div className="flex-grow-1 ms-3">
                            <h5 className="mb-0">Bob Dean</h5>
                            <small className="ms-auto">11 mins ago</small>
                        </div>
                        <div className="text-end">
                            <button type="button" className="btn-close" data-bs-dismiss="toast" aria-label="Close" />
                        </div>
                    </div>
                </div>
                <div className="toast-body">Hello, world! This is a toast message.</div>
            </div>
            {/* End Toast */}
        </>
    );
};

export default DetailPage;
